"use strict";

import axios from "axios";
import config from "config";

const apiURIV2 = config.environment.apiV2.uri + "/api/v2";
const measurementsV2URI = `${apiURIV2}/water/consumption`;
const metricsV2URI = `${apiURIV2}/water/metrics`;

/**
 * Consumption API Interface
 */
class _Consumption {

  /**
   * Get Water Consumption endpoint
   * 
   * @param {*} startDate 
   * @param {*} endDate 
   * @param {*} locationId 
   * @param {*} interval 
   * @param {*} tz 
   */
  getMeasurements(startDate, endDate, locationId, interval = "1h", tz) {
    const params = [];

    params.push(`startDate=${startDate}`);
    params.push(`endDate=${endDate}`);
    params.push(`locationId=${locationId}`);
    params.push(`interval=${interval}`);
    if (tz) {
      params.push(`tz=${tz}`);
    }
    const parameters = (params.length > 0) ? `?${params.join("&")}` : '';
    return axios.get(measurementsV2URI + parameters).then(success => {
      const data = success.data;
      return data.items.map(c => ({
        total_flow: c.gallonsConsumed,
        time: c.time
      }));
    });
  }

  /**
   * Get Metrics
   * 
   * @param {*} startDate 
   * @param {*} endDate 
   * @param {*} macAddress 
   * @param {*} interval 
   * @param {*} tz 
   */
  getMetrics(startDate, endDate, macAddress, interval = '1h', tz) {
    const params = [];

    params.push(`startDate=${startDate}`);
    params.push(`endDate=${endDate}`);
    params.push(`macAddress=${macAddress}`);
    params.push(`interval=${interval}`);
    if (tz) {
      params.push(`tz=${tz}`);
    }
    const parameters = (params.length > 0) ? `?${params.join("&")}` : '';
    return axios.get(metricsV2URI + parameters).then(success => {
      const data = success.data;
      return data.items.map(c => ({
        averageGpm: c.averageGpm || 0,
        averagePsi: c.averagePsi || 0,
        averageTempF: c.averageTempF || 0,
        time: c.time,
      }));
    });
  }
}
/**
 * @ignore
 */
export const Consumption = new _Consumption();
export default Consumption;
