"use strict";

// Framework & Libraries
import axios from "axios";
import { browserHistory } from "react-router";

// Constants
import { AUTH_RECEIVE_TOKEN, AUTH_EXPIRE_TOKEN, AUTH_LOGIN_START, AUTH_LOGIN_STOP, AUTH_LOGIN_FAILURE, AUTH_LOGIN_CLEAR_ERR, UPDATE_USER_TOKEN_INFO } from "constants/action";
import { AUTH_LOCAL_STORAGE_TOKEN_VALUE, AUTH_LOCAL_STORAGE_TOKEN_EXP, AUTH_COOKIE_TOKEN } from "constants/name";

// API
import AuthenticationAPI from "api/authentication";
import ErrorAPI from "api/error";

// Store
import Store from "store";
import SafeQuery from "./common/SafeQuery";

// Reducer formatters
const receivedToken = ( token, expiration ) => ({ type: AUTH_RECEIVE_TOKEN, token: { value: token, expiration: expiration } });
const expireToken = () => ({ type: AUTH_EXPIRE_TOKEN });
const loginStart = () => ({ type: AUTH_LOGIN_START });
const loginStop = () => ({ type: AUTH_LOGIN_STOP });
const loginFailure = msg => ({ type: AUTH_LOGIN_FAILURE, message: msg });
const clearErrors = () => ({ type: AUTH_LOGIN_CLEAR_ERR });
const updateUserTokenInfo = (userTokenInfo) => ({ type: UPDATE_USER_TOKEN_INFO, data: userTokenInfo});

const PRESENCE_INTERVAL = 30000; // 30 sec

/**
 * Authentication Actions module
 */
class _Authentication extends SafeQuery {
  /*
   * Last health Check persisting value
   */
  lastHealthCheck = 0;
  /**
   * Tasks to manage after the auth token has been returned
   * @param { string } token - JWT: JSON Web Token for authorization
   * @param { number } expiration - how long until the token expires
   * @param { string } redirectPath - post login redirect path if other than '/login'
   * @return { undefined } - returns nothing
   */
  loginCompleted = ( token, expiration, redirectPath ) => {
    Store.dispatch( loginStop() );
    Store.dispatch( receivedToken( token, expiration ) );
    this.localSessionAuth( token, expiration );
    browserHistory.push( redirectPath );
    this.clearErrorMessages();
  }
  /**
   * Check's to see if the user is logged in according to local and remote values
   * @return { Promise } - that the user is logged in
   */
  isLoggedIn = () => {
		return new Promise( ( fulfill, reject ) => {
			if ( this.localSessionCheck() ){
				this
					.tokenHealthCheck()
					.then( fulfill )
					.catch( reject );
			} else {
        const token = Store.getState().authentication.authToken
				if( token.value !== null && new Date().getTime() < token.expiration ){
					fulfill();
				} else {
          reject();
        }
			}
		});
  }
  /**
   * Determine if the 'local session' has timed out. If not, set the authorization token for all requests
   * @return { Boolean } - if the local session is still valid
   */
  localSessionCheck = () => {
    if (typeof Storage !== "undefined") {
      if ( localStorage[ AUTH_LOCAL_STORAGE_TOKEN_EXP ] !== null ){
        if ( new Date().getTime() < localStorage[ AUTH_LOCAL_STORAGE_TOKEN_EXP ] ) {
          if ( localStorage[ AUTH_LOCAL_STORAGE_TOKEN_VALUE ] !== null ) {
            Store.dispatch(
              receivedToken(
                localStorage[ AUTH_LOCAL_STORAGE_TOKEN_VALUE ],
                localStorage[ AUTH_LOCAL_STORAGE_TOKEN_EXP ]
              )
            );
            axios.defaults.headers.common.authorization = localStorage[ AUTH_LOCAL_STORAGE_TOKEN_VALUE ];
            return true;
          }
        }
      }
    } else if ( typeof document.cookie !== "undefined" ) {
      if ( document.cookie.search( AUTH_COOKIE_TOKEN ) > -1 ) {
        axios.defaults.headers.common.authorization = document.cookie.split( AUTH_COOKIE_TOKEN + "=" )[ 1 ].split( ";" )[ 0 ];
        return true;
      }
    }
    axios.defaults.headers.common.authorization = null;
    return false;
  }
  /**
   * Sets the local session with the JWT
   * @return { undefined } - returns nothing
   */
  localSessionAuth = ( token, expiration ) => {
    if ( typeof Storage !== "undefined" ) {
      localStorage.setItem( AUTH_LOCAL_STORAGE_TOKEN_VALUE, token );
      localStorage.setItem( AUTH_LOCAL_STORAGE_TOKEN_EXP, expiration );
    } else if ( typeof document.cookie !== "undefined" ){
      document.cookie = AUTH_COOKIE_TOKEN + "=" + token + "; secure; expires=" + expires + "; path=/";
    }
  }
  /**
   * Unsets local session with JWT
   * @return { undefined } - returns nothing
   */
  localSessionDeAuth = () => {
    if ( typeof Storage !== "undefined" && localStorage[ AUTH_LOCAL_STORAGE_TOKEN_VALUE ] ) {
      localStorage[ AUTH_LOCAL_STORAGE_TOKEN_VALUE ] = null;
      localStorage[ AUTH_LOCAL_STORAGE_TOKEN_EXP ] = null;
    } else if ( typeof document.cookie !== "undefined" ) {
      document.cookie = AUTH_COOKIE_TOKEN + "=; secure; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }
  }
  /**
   * Send's logout and starts local deauthorizations
   * @param { string } reason - for loggout action
   * @return { undefined } - returns nothing
   */
  logout = reason => {
    if ( reason ) {
      console.log( "LOGOUT REASON: ", reason );
    }
    this.isLoggedIn()
      .then( () => {
        AuthenticationAPI.logout();
        this.lastHealthCheck = 0;
        this.localSessionDeAuth();
        Store.dispatch( expireToken() );
        window.location = "/login";
      })
      .catch( () => {
        window.location = "/login";
      });
  }
  /**
   * Clear's authorization related error message
   * @return { undefined } - returns nothing
   */
  clearErrorMessages(){
    Store.dispatch( clearErrors() );
  }
  /**
   * Set's a NEW password from the user
   * @param { string } currPass - Current user's password for validation
   * @param { string } newPass - New Password to replace current
   * @param { string } newPassConf - Confirmation of the replacement password
   * @return { Promise } - if the password has been set
   */
  setPassword = ( currPass, newPass, newPassConf ) => {
    return new Promise( ( fulfill, reject ) => {
      AuthenticationAPI.setPassword( currPass, newPass, newPassConf )
        .then( response => {
          if ( !response.error ) {
            this.logout();
            fulfill( true );
          } else {
            if ( response.message ) {
              reject( "Error: " + response.message );
            } else {
              reject( "Please make sure your current password is correct and your new password conforms to password complexity" );
            }
          }
        }, rejection => {
          console.log( rejection, rejection.message, rejection.data );
        });
    });
  }
  /**
   * Sends request to send password reset email
   * @param { string } email - Email to send a password reset token too
   * @return { Promise } - that the password has been reset
   */
  resetPasswordRequest = email => {
    if ( !email ){
      throw new Error( "Email required" );
      return;
    }
		return AuthenticationAPI.resetPasswordRequest( email );
  }
  /**
   *
   */
  resetPassword = ( password, userId, resetToken ) => {
    if ( !password || !userId || !resetToken ) {
      throw new Error( "Missing an argument: password, userId, resetToken" );
      return;
    }
    return new Promise( fulfill => {
      AuthenticationAPI.resetPasswordRequestToken( userId, resetToken  )
        .then( token => AuthenticationAPI.resetPassword( password, token ) )
        .then( success => {
          if ( success ) {
            // look for referer to go to device or '/login'
            fulfill( () => {
              browserHistory.push( "/login" )
            });
          }
        });
    })
  }
  /**
   * Ping server with token to ensure validity
   * @return { Promise } - that the token is healthy
   */
  tokenHealthCheck = () => {
		return new Promise( ( fulfill, reject ) => {
      // timeout: 1000 miliseconds -> 1 second * 60 -> 1 minute
      const timeout = 1000 * 60;
			if ( this.lastHealthCheck === 0 || new Date().getTime() - this.lastHealthCheck > timeout ) {
				this.lastHealthCheck = new Date().getTime();
				AuthenticationAPI
					.tokenHealthCheck()
					.then( () => {
            fulfill();
          })
					.catch( err => {
						this.logout(err);
						reject();
					});
			} else {
				fulfill();
			}
		});
  }
  /**
   * Login as a specific user from a login with priviliges to do so ( fires 'loginCompleted', or 'loginStop' action )
   * @param { string } email - Email with priviliges to impersonate
   * @param { string } pass - Password for email with impersonate priviliges
   * @param { string } uid - UserId to impersonate
   * @param { string } postPath - path to continue to after authentication has taken place
   * @return { undefined } - returns nothing
   */
  impersonate = ( email, pass, uid, postPath, mixpanel ) => {
    if ( !email || !pass ) {
      Store.dispatch( loginFailure( "Email and password required" ) );
      Store.dispatch( loginStop() );
      throw new Error( "Email and password required" );
      return;
    }
		Store.dispatch( loginStart() );
		AuthenticationAPI
			.impersonate( email, pass, uid )
			.then( res => {
        this.loginCompleted( res.data.token, ( res.data.timeNow * 1000 ) + ( res.data.tokenExpiration * 1000 ), postPath );
      }, err => {
        Store.dispatch( loginFailure( err.response ? err.response.data.message : err ) );
        Store.dispatch( loginStop() );
      });
  }
  /**
   * Request auth token
   * @param { string } email - Email of user to request token as
   * @param { string } pass - Password mathcing email for request
   * @param { string } postPath - path to continue to after authentication has taken place
   * @return { undefined } - returns nothing
   */
  requestToken = ( email, pass, postPath, mixpanel ) => {
    if ( !email || !pass ) {
      Store.dispatch( loginFailure( "Email and password required" ) );
      Store.dispatch( loginStop() );
      throw new Error( "Email and password required" );
    }
    Store.dispatch( loginStart() );
    AuthenticationAPI
      .requestToken( email, pass )
      .then( res => {
        this.identifyUserInMixpanel(mixpanel, res.data.tokenPayload);
        this.loginCompleted( res.data.token, ( res.data.timeNow * 1000 ) + ( res.data.tokenExpiration * 1000 ), postPath );
      }, err => {
        Store.dispatch( loginFailure( err.response ? err.response.data.message : err ) );
        Store.dispatch( loginStop() );
      });
  }

  validatePassword(email, password) {
    return AuthenticationAPI
      .requestToken(email, password)
      .then(() => ({ is_valid: true }));
  }

  identifyUserInMixpanel(mixpanel, tokenPayload) {
    if (!mixpanel.people._identify_called()) {
      mixpanel.identify(tokenPayload.user.user_id);

      mixpanel.people.set({
        '$email': tokenPayload.user.email,
        'source': 'user-portal'
      });
    }
  }

  getUserTokenInfo = () => {
    const tokenInfo = Store.getState().authentication.userTokenInfo;

    if (tokenInfo.user_id) {
      return Promise.resolve(tokenInfo);
    }

    const getUserTokenInfoQuery = this.createQuery('Authentication.getUserTokenInfo', AuthenticationAPI.getUserTokenInfo);
    return getUserTokenInfoQuery()
      .then(tokenInfo => {
        Store.dispatch(updateUserTokenInfo(tokenInfo));
        return tokenInfo;
      });
  }

  /**
   * Sets presence in the system based on JWT token. This is required for any realtime data streamin. 
   * Expires after 60s, unless called again. Suggested interval to call is every 30s.
   */
  startSendingPresence() {
    const send = () => AuthenticationAPI.notifyPresence();
    send();
    this.presenceIntervalId = setInterval(send, PRESENCE_INTERVAL);
  }

  stopSendingPresence() {
    if (this.presenceIntervalId) {
      clearInterval(this.presenceIntervalId);
    }
  }
}

/**
 * @ignore
 */
export const Authentication = new _Authentication();
export default Authentication;

// Decorator
export const Authenticate = () => ( ( target, prop, descriptor ) => {
  const orgMethod = descriptor.value;
  descriptor.value = ( ...args ) => {
    return Authentication.isLoggedIn().then( () => orgMethod.apply( target, args ) );
  };
  return descriptor;
});
