"use strict";

import React, { Component } from "react";
import { connect }          from "react-redux";

import config               from "config";

import User									from "actions/user";
import Setting              from "actions/setting";

import FloCheckbox          from "elements/FloCheckbox";
import FloGroupTogglebox    from "elements/FloGroupTogglebox";
import {Helmet}             from "react-helmet";
import _                    from "lodash";
import deviceModels         from "constants/deviceModels";
import NoDevicesCard        from '../elements/NoDevicesCard';

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

/**
 * Notification Preferences view
 */
export class Settings_Notifications extends Component {
  /**
   * React lifecycle: Constructor, Build's component's state
   * @constructor
	 * @param { Object } props - Initial incomming properties used to set component's initial state
   */
	constructor( props ){
    super(props);

    this.state = {
      criticalCollapsed: true,
      warningCollapsed: true,
      informativeCollapsed: true,
      dropdownOpen: false,
      selectedDeviceIndex: 0
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.locationId !== this.props.locationId) {
      this.setState({ selectedDeviceIndex: 0 });
    }
  }

  componentDidMount(){
    Setting.hydrateStore();
		User.hydrateStore();
  }

  toggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  togglePreference = (alarmId, systemMode, deliveryMedium) => {
    this.togglePreferences([ alarmId ], systemMode, deliveryMedium);
	}

  togglePreferences = (alarmKeys, systemMode, deliveryMedium, setValue ) => {
    const preferences = this.getCurrentPreferences();
    const currentDeviceId = this.props.devices[this.state.selectedDeviceIndex].id;
    const mediumKey = `${deliveryMedium}Enabled`;

    const settings = alarmKeys.map(alarmId => {
      const alarm = preferences.critical[ alarmId ] || preferences.warning[ alarmId ] || preferences.info[ alarmId ];
      const deliveryMediums = alarm[systemMode];
      if (setValue !== undefined && deliveryMediums[mediumKey] !== undefined) {
        deliveryMediums[mediumKey] = setValue;
      } else if (deliveryMediums[mediumKey] !== undefined) {
        deliveryMediums[mediumKey] = !deliveryMediums[mediumKey];
      }

      return {
        alarmId: (typeof alarmId !== 'number') ? parseInt(alarmId, 10) : alarmId,
        systemMode,
        severity: deliveryMediums.severity,
        emailEnabled: deliveryMediums.emailEnabled,
        smsEnabled: deliveryMediums.smsEnabled,
        pushEnabled: deliveryMediums.pushEnabled,
        callEnabled: deliveryMediums.callEnabled,
      };
    });

    Setting.saveNotificationPreferences(this.props.userId, currentDeviceId, settings);
  }

  handleDeviceChanged(index) {
    this.setState({
      selectedDeviceIndex: index,
      criticalCollapsed: true,
      warningCollapsed: true,
      informativeCollapsed: true,
    });
  }

  updateGroup( severity, systemMode, deliveryMedium, proxy ){
    proxy.stopPropagation();
    const preferences = this.getCurrentPreferences();
    const status = this.getGroupStatus( severity, systemMode, deliveryMedium );
    if ( status === "all" || status === "partial" ) {
      const alarmIds = [];
      Object.keys( preferences[ severity ] ).forEach( alarmKey => {
        if ( preferences[ severity ][ alarmKey ][ systemMode ] ) {
          alarmIds.push(alarmKey);
        }
      });
      this.togglePreferences(alarmIds, systemMode, deliveryMedium, false);
    } else {
      const alarmIds = [];
      Object.keys( preferences[ severity ] ).forEach( alarmKey => {
        if ( preferences[ severity ][ alarmKey ][ systemMode ] ) {
          alarmIds.push(alarmKey);
        }
      });
      this.togglePreferences(alarmIds, systemMode, deliveryMedium, true);
    }
  }

  getGroupStatus( severity, systemMode, deliveryMedium ){
    const preferences = this.getCurrentPreferences();

    if ( Object.keys( preferences[ severity ] ).every( alarmId => {
      if (!preferences[ severity ][ alarmId ][ systemMode ]) { return true; }
      if ( preferences[ severity ][ alarmId ][ systemMode ].name === "water_system_shutoff" ){ return true; }
      if ( preferences[ severity ][ alarmId ][ systemMode ][ `${deliveryMedium}Enabled` ] !== undefined ) {
        return preferences[ severity ][ alarmId ][ systemMode ][ `${deliveryMedium}Enabled` ];
      } else {
        return true;
      }
    }) ){
      return "all";
    }
    if ( Object.keys( preferences[ severity ] ).some( alarmId => {
      if (!preferences[ severity ][ alarmId ][ systemMode ]) { return false; }
      if ( preferences[ severity ][ alarmId ][ systemMode ].name === "water_system_shutoff" ){ return false; }
      if ( preferences[ severity ][ alarmId ][ systemMode ] ) {
        return preferences[ severity ][ alarmId ][ systemMode ][ `${deliveryMedium}Enabled` ];
      } else {
        return false;
      }
    }) ){
      return "partial";
    }
    return "none";
  }

  toggleRowVisibility = ( severity ) => {
    const severityCollapsed = `${severity}Collapsed`;

    this.setState({
      [severityCollapsed]: !this.state[severityCollapsed]
    });
  }

  renderSmallDripCheckboxes() {
    const preferences = this.getCurrentPreferences();

    const smallDripPreferences = _.toPairs(preferences.warning || {})
      .filter(x => x[1].home && x[1].home.name === "small_drip_detected")
      .map(x => x[1]);

    const smallDripPreferencesKeys = _.toPairs(preferences.warning || {})
      .filter(x => x[1].home && x[1].home.name === "small_drip_detected")
      .map(x => x[0]);

    const preference = smallDripPreferences[0];
    if (!preference) { return null; }

    const smallDripName = _.get(preference, 'home.displayName', '');
    const smallDripHomePrefs = _.get(preference, 'home', {});
    const smallDripAwayPrefs = _.get(preference, 'away', {});

    return (
      <tr className={ "text-center " + ((this.state.warningCollapsed) ? "hidden" : "") }>
        <td className="alarm-name text-left">{ smallDripName }</td>
        <td>
          <FloCheckbox className="light" onClick={()=>this.togglePreferences(smallDripPreferencesKeys, "home", "email" )} checked={ smallDripHomePrefs.emailEnabled } />
        </td>
        <td>
          <FloCheckbox className="light" onClick={()=>this.togglePreferences(smallDripPreferencesKeys, "home", "sms" )} checked={ smallDripHomePrefs.smsEnabled } />
        </td>
        <td>
          <FloCheckbox className="light" onClick={()=>this.togglePreferences(smallDripPreferencesKeys, "home", "push" )} checked={ smallDripHomePrefs.pushEnabled } />
        </td>
        <td></td>
        <td>
          <FloCheckbox className="light" onClick={()=>this.togglePreferences(smallDripPreferencesKeys, "away", "email" )} checked={ smallDripAwayPrefs.emailEnabled } />
        </td>
        <td>
          <FloCheckbox className="light" onClick={()=>this.togglePreferences(smallDripPreferencesKeys, "away", "sms" )} checked={ smallDripAwayPrefs.smsEnabled }/>
        </td>
        <td>
          <FloCheckbox className="light" onClick={()=>this.togglePreferences(smallDripPreferencesKeys, "away", "push" )} checked={ smallDripAwayPrefs.pushEnabled }/>
        </td>
        <td></td>
      </tr>
    );
  }

  getCurrentPreferences() {
    const { alarmSettings, devices } = this.props;
    const { selectedDeviceIndex } = this.state;

    if (!alarmSettings || devices.length < 1) {
      return {
        critical: [],
        warning: [],
        info: []
      };
    }

    const deviceSettings = alarmSettings.find(s => s.deviceId === devices[selectedDeviceIndex].id) || {};
    return deviceSettings.settings;
  }

  renderGroup(systemModes, deliveryMediums, severity, severityTitle) {
    const header = [ (<td className="severity text-left">{severityTitle}</td>) ];

    return systemModes.reduce((acc, systemMode) => {
      const newCols = deliveryMediums.map(deliveryMedium => {
        return (deliveryMedium) ? (
          <td key={`${severity}-${systemMode}-${deliveryMedium}-title}`}>
            <FloGroupTogglebox className="light" display={ this.getGroupStatus(severity, systemMode, deliveryMedium) } onClick={ this.updateGroup.bind( this, severity, systemMode, deliveryMedium) }/>
          </td>
        ) : (<td></td>);
      });
      return [ ...acc, ...newCols ];
    }, header);
  }

  renderRow(alarmId, systemModes, deliveryMediums, severity, isNested = false) {
    const name = systemModes.reduce((acc, curr) => (curr.preferences.displayName && acc === "") ? curr.preferences.displayName : acc, "");
    const header = [ (<td className={`${isNested ? 'water-shutoff' : 'alarm-name'} text-left`}>{ name }</td>) ];

    return systemModes.reduce((acc, systemMode) => {
      const newCols = deliveryMediums.map(deliveryMedium => {
        return (deliveryMedium && systemMode.preferences[`${deliveryMedium}Enabled`] !== undefined) ? (
          <td key={`${severity}-${systemMode.id}-${deliveryMedium}-${alarmId}`}>
            <FloCheckbox className="light" onClick={()=>this.togglePreference( alarmId, systemMode.id, deliveryMedium)} checked={ systemMode.preferences[`${deliveryMedium}Enabled`] } />
          </td>
        ) : (<td></td>);
      });
      return [ ...acc, ...newCols ];
    }, header);
  }

  renderNoDevicesCard() {
    return (
      <section className="container settings-notifications">
        <Helmet>
          <title>Flo - Alert Settings</title>
        </Helmet>
        <NoDevicesCard
          description = "This home currently has no connected devices. To edit alert settings, please use the Flo by Moen Mobile App to add devices."
        />
      </section>
    )
  }

  render() {
    const { devices } = this.props;
    const preferences = this.getCurrentPreferences();

    if (devices.length === 0) {
      return this.renderNoDevicesCard();
    }

    const device = (devices.length > 0) ? devices[this.state.selectedDeviceIndex] : {};

    const dropdownItems = devices
        .map((d, i) => ({ ...d, index: i }))
        .filter(d => d.id !== device.id)
        .map(d => (
          <DropdownItem onClick={() => this.handleDeviceChanged(d.index)} key={`device-setting-${d.id}`}>
            { d.nickname || deviceModels.getDeviceTypeLabel(d.deviceModel, d.deviceType) }
          </DropdownItem>
        ));

    return (
      <section className="container settings-notifications">
        <Helmet>
          <title>Flo - Alert Settings</title>
        </Helmet>

        <div className="card">
          <div className="card-header">
            <div className="title">Alert Settings</div>
            <div className="device-dropdown text-center">
              <Dropdown
                isOpen={this.state.dropdownOpen}
                toggle={this.toggle}
                className="device-dropdown"
                disabled={!device.id}
              >
                <DropdownToggle caret>
                  { device.id && (device.nickname || deviceModels.getDeviceTypeLabel(device.deviceModel, device.deviceType)) }
                  { !device.id && "Loading... " }
                </DropdownToggle>
                { device.id && dropdownItems.length > 0 &&
                  <DropdownMenu>
                    { dropdownItems }
                  </DropdownMenu>
                }
              </Dropdown>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <p>
                  Opting out of any Alerts on this page will only stop you from receiving that notification. In the case of a critical alert, Flo will still shut off your water.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                    <tr className="sub-headings text-center">
                      <th className="spacer" scope="col"></th>
                      <th className="home-mode" colSpan="4" scope="col"><em>Home <span className="mode">Mode</span></em></th>
                      <th className="away-mode" colSpan="4" scope="col"><em>Away <span className="mode">Mode</span></em></th>
                    </tr>
                    <tr className="headings text-center">
                      <th scope="col"></th>
                      <th scope="col">Email</th>
                      <th scope="col">Text</th>
                      <th scope="col">Push</th>
                      <th scope="col">Call</th>
                      <th scope="col">Email</th>
                      <th scope="col">Text</th>
                      <th scope="col">Push</th>
                      <th scope="col">Call</th>
                    </tr>
                    </thead>
                    <tfoot></tfoot>
                    <tbody>
                    <tr className={ "title-row critical text-center " + ((this.state.criticalCollapsed) ? "closed" : "open") } onClick={ () => this.toggleRowVisibility("critical") }>
                      { this.renderGroup(["home", "away"], ["email", "sms", "push", "call"], "critical", "Critical") }
                    </tr>
                    { Object.keys(preferences.critical || {} )
                    .filter(x => preferences.critical[ x ].home || preferences.critical[ x ].away)
                    .reduce( (acc, alarmId) => {
                      const rows = [ ...acc ];
                      const alarmKey = alarmId;
                      const homePreferences = preferences.critical[ alarmKey ].home || {};
                      const awayPreferences = preferences.critical[ alarmKey ].away || {};
                      const systemModes = [
                        { id: "home", preferences: homePreferences },
                        { id: "away", preferences: awayPreferences }
                      ];
                      const deliveryMediums = ["email", "sms", "push", "call"];
                      const alarmData = preferences.critical[ alarmKey ].home || preferences.critical[ alarmKey ].away;

                      if (!alarmData.triggeredBy) {
                        rows.push(
                          <tr key={ alarmKey } className={ "text-center " + ((this.state.criticalCollapsed) ? "hidden" : "") }>
                            { this.renderRow(alarmKey, systemModes, deliveryMediums, "critical") }
                          </tr>
                        );
                      }

                      // Nested alarms: Renders the alarm triggered by the current alarm.
                      if (alarmData.triggersAlarm) {
                        const smNested = [
                          { id: "home", preferences: preferences.critical[ alarmData.triggersAlarm ].home || {} },
                          { id: "away", preferences: preferences.critical[ alarmData.triggersAlarm ].away || {} }
                        ];
                        rows.push(
                          <tr key={ alarmData.triggersAlarm } className={ "text-center " + ((this.state.criticalCollapsed) ? "hidden" : "") }>
                            { this.renderRow(alarmData.triggersAlarm, smNested, ["email", "sms", "push", null], "critical", true) }
                          </tr>
                        );
                      }
                      return rows;
                    }, [])}
                    <tr className={ "title-row warning text-center " + ((this.state.warningCollapsed) ? "closed" : "open") } onClick={ () => this.toggleRowVisibility("warning") }>
                      { this.renderGroup(["home", "away"], ["email", "sms", "push", null], "warning", "Warning") }
                    </tr>
                    {Object.keys( preferences.warning || {} )
                      .filter( x => {
                        const pref = preferences.warning[ x ].home || preferences.warning[ x ].away;
                        return pref && pref.name !== "small_drip_detected";
                      })
                      .map( alarmKey => {

                        const homePreferences = preferences.warning[ alarmKey ].home || {};
                        const awayPreferences = preferences.warning[ alarmKey ].away || {};
                        const systemModes = [
                          { id: "home", preferences: homePreferences },
                          { id: "away", preferences: awayPreferences }
                        ];
                        const deliveryMediums = ["email", "sms", "push", null];

                        return (
                          <tr key={ alarmKey } className={ "text-center " + ((this.state.warningCollapsed) ? "hidden" : "") }>
                            { this.renderRow(alarmKey, systemModes, deliveryMediums, "warning") }
                          </tr>
                        );
                      })
                    }
                    {
                      this.renderSmallDripCheckboxes()
                    }
                    <tr className={ "title-row info text-center " + ((this.state.informativeCollapsed) ? "closed" : "open") } onClick={ () => this.toggleRowVisibility("informative") }>
                      { this.renderGroup(["home", "away"], ["email", "sms", "push", null], "info", "Informative") }
                    </tr>
                    {Object.keys( preferences.info || {} ).map(alarmKey => {
                      const homePreferences = preferences.info[ alarmKey ].home || {};
                      const awayPreferences = preferences.info[ alarmKey ].away || {};
                      const systemModes = [
                        { id: "home", preferences: homePreferences },
                        { id: "away", preferences: awayPreferences }
                      ];
                      const deliveryMediums = ["email", "sms", "push", null];

                      return (
                        <tr key={ alarmKey } className={ "text-center " + ((this.state.informativeCollapsed) ? "hidden" : "") }>
                          { this.renderRow(alarmKey, systemModes, deliveryMediums, "info") }
                        </tr>
                      );
                    })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default connect( state => {
  return {
		userId: state.user.id,
    locationId: state.user.currentLocation.id,
    devices: state.device.list,
    alarmSettings: state.user.alarmSettings,
	};
})(Settings_Notifications);
