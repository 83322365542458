"use strict";

import {
  UPDATE_EMAIL_SUBSCRIPTIONS,
  UPDATE_SUBSCRIPTION_STATUS
} from "constants/action";

import SubscriptionStatus from 'constants/SubscriptionStatus';

const initialState = {
  subscriptionStatus: SubscriptionStatus.UNKNOWN,
  preferences: []
};

const emailPreferences = (state = initialState, action) => {
  switch(action.type) {
    case UPDATE_SUBSCRIPTION_STATUS: {
      return {
        ...state,
        subscriptionStatus: action.data
      };
    };break;
    case UPDATE_EMAIL_SUBSCRIPTIONS: {
      return {
        ...state,
        preferences: {
          ...action.data
        }
      };
    };break;
    default:
      return state;
  }
  return state;
};

export default emailPreferences;
