"use strict";

import React, { Component }     from "react";
import PropTypes                from 'prop-types';
import { connect }              from "react-redux";

import LocationProfileActions   from "actions/locationProfile";
import DeviceStatus             from 'constants/DeviceStatus';
import SystemModeSelector       from 'elements/SystemModeSelector';

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import _ from "lodash";


export class LocationSelector extends Component {

  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.handleLocationClick = this.handleLocationClick.bind(this);
    this.handleSetSystemMode = this.handleSetSystemMode.bind(this);
    this.handleSetSystemSleep = this.handleSetSystemSleep.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  handleSetSystemMode(systemModeId) {
    return LocationProfileActions.setSystemMode(systemModeId);
  }

  handleSetSystemSleep(systemModeId, minutes) {
    return LocationProfileActions.setSystemSleep(systemModeId, minutes);
  }

  handleLocationClick(locationId) {
    LocationProfileActions.changeCurrentLocation(locationId);
  }

  disabled() {
    return DeviceStatus.isDisabled(this.props.device.status);
  }

  getCurrentRouteName() {
    if (!this.context.router) {
      return '';
    }
    const  routes = this.context.router.routes;
    const index = routes.length - 2;
    return index > 0 ? routes[routes.length - 2].name : '';
  }

  renderLocationName(location) {
    if (location.nickname) {
      return location.nickname;
    } else if (location.address && location.city) {
      return `${location.address}, ${location.city} ${(location.state) ? location.state.toUpperCase() : ""}`;
    } else if (location.address) {
      return `${location.address} ${(location.state) ? location.state.toUpperCase() : ""}`;
    }
  }

  render() {
    const { locations, current, device, systemMode } = this.props;
    const validLocations = locations.filter(loc => loc.isProfileComplete || loc.nickname || loc.address || loc.city);

    const dropdownItems = validLocations.filter(loc => loc.id !== current.id).map(loc => (
      <DropdownItem onClick={() => this.handleLocationClick(loc.id)} key={`item-${loc.id}`}>
        {this.renderLocationName(loc)}
      </DropdownItem>)
    );

    return (
      <div className="container location-selector">
        <div className="location-dropdown">
          { current.id && locations.length > 1 &&
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} >
              <DropdownToggle caret>
                {this.renderLocationName(current)}
              </DropdownToggle>
              <DropdownMenu>
                { dropdownItems }
              </DropdownMenu>
            </Dropdown>
          }
          { current.id && validLocations.length === 1 && <div className="single-location">{this.renderLocationName(current)}</div> }
          { !current.id &&
            <div className="loader-wrapper"><div className="loader"></div></div>
          }
        </div>
        { (this.getCurrentRouteName() === 'home') &&
        <div className="system-mode">
          <SystemModeSelector
            selectedSystemMode = { systemMode }
            onSetSystemMode = { this.handleSetSystemMode }
            onSetSystemSleep = { this.handleSetSystemSleep }
            disabled = { this.disabled() /*|| device.isLearning*/ }
          />
        </div>
        }
      </div>
    );
  }
}

const mapStateToProps = store => {
  const currentDevice = (store.device.list.length > 0 && store.device.currentMacAddress) ? store.device.list.find(d => d.device_id === store.device.currentMacAddress) : {};
  return {
    locations: store.user.locations,
    current: store.user.currentLocation,
    device: {
      status: store.device.status,
      isLearning: currentDevice.isLearning
    },
    systemMode: _.get(store, 'user.currentLocation.systemMode.target')
  };
};

LocationSelector.contextTypes = {
  router: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(LocationSelector);
