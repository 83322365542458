import React, { PureComponent } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withState, withHandlers, compose, mapProps } from 'recompose';
import { CSVLink } from 'react-csv';
import { Link } from "react-router";
import { Popover, PopoverBody } from 'reactstrap';

const mapCSVData = (data) => {
  if (data.length === 0) {
    return [];
  }

  if (data.length === 1) {
    return data[0];
  }

  return data[0]
    .concat([['', '']])
    .concat(data[1]);
};

class DataDownloader extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      popoverDownloadBlocked: false
    };
  }

  toggleDownloadBlockedPopover = () => {
    this.setState({
      popoverDownloadBlocked: !this.state.popoverDownloadBlocked
    });
  }

  render() {
    const { isBlocked, isOpen, toggle, data, className, filename} = this.props;
    const popoverTargetId = `popover-download-blocked-${Math.ceil(Math.random() * 10000)}`;

    return (
      <div className={ `data-download ${className}` } >
        { isBlocked ?
          (
            <Link to="/floprotect" className="btn btn-primary">
              Download <a
                id={ popoverTargetId }
                className="popover-icon"
                onMouseEnter={ this.toggleDownloadBlockedPopover }
                onMouseLeave={ this.toggleDownloadBlockedPopover }
              >
                <i className="fas fa-lock ml-2"></i>
              </a>
              <Popover
                isOpen={this.state.popoverDownloadBlocked}
                target={ popoverTargetId }
                toggle={ this.toggleDownloadBlockedPopover }
              >
                <PopoverBody>You must have FloProtect enabled to download data</PopoverBody>
              </Popover>
            </Link>
          ) :
          (
            <Dropdown isOpen={isOpen} toggle={toggle} className="data-downloader">
              <DropdownToggle caret color="primary">
                Download
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem >
                  <CSVLink data={data} filename={ filename || "flo-data.csv" }>as CSV</CSVLink>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          )
        }
      </div>);
  }
};

export default compose(
  mapProps(props => ({ ...props, data: mapCSVData(props.data) })),
  withState('isOpen', 'toggleOpen', false),
  withHandlers({
    toggle: ({ isOpen, toggleOpen }) => () => toggleOpen(!isOpen)
  }),

)(DataDownloader);
