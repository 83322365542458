"use strict";

import axios  from "axios";
import config from "config";

const weeklyReportUrl = `${config.environment.api.uri}/api/v1/weeklyreports`;
const subscribeUrl = `${weeklyReportUrl}/subscribe`;
const unsubscribeUrl = `${weeklyReportUrl}/unsubscribe`;
const retrieveWeeklySubscriptionByUserIdUrl = userId => `${weeklyReportUrl}/user/${userId}`;
const unsubscribeByEmbeddedLinkUrl = (u, l, n, t)  => `${weeklyReportUrl}/${u}/${l}/${n}/unsubscribe?t=${t}`;

class _WeeklyReport {

  subscribe(userId, locationId) {
    return axios.post(
      subscribeUrl,
      {
        user_id: userId,
        location_id: locationId
      });
  }

  unsubscribe(userId, locationId) {
    return axios.post(
      unsubscribeUrl,
      {
        user_id: userId,
        location_id: locationId
      });
  }

  retrieveWeeklySubscriptionByUserId(userId) {
    return axios.get(retrieveWeeklySubscriptionByUserIdUrl(userId)).then(x => x.data);
  }

  unsubscribeByEmbeddedLink(userId, locationId, unsubscribeToken, accessToken) {
    return axios.get(
      unsubscribeByEmbeddedLinkUrl(userId, locationId, unsubscribeToken, accessToken)
    );
  }
}

/**
 * @ignore
 */
export const WeeklyReport = new _WeeklyReport();
export default WeeklyReport;
