"use strict";

import React, { Component } from "react";
import config from "config";
/*
 * Redirect Component
 */
export class Redirect extends Component {
  /**
   * Set Redirect Component's initial state
   * @param { Object } props - component initiliziation properties
   */
  constructor( props ){
    super( props );
    this.state = { ...props };
  }
  /**
   * React Lifecycle: componentWillMount - This happens before the component mounts but will not stop the mounting progression
   * Sends to redirect based on the incoming route information.
   */
  componentWillMount(){
    if ( this.state.route.loc === "AppStore" ) {
      if ( navigator.userAgent.search( /Android/ )  > -1) {
        window.location = "market://details?id=com.flotechnologies";
      } else if ( navigator.userAgent.search( /iPhone|iPod/ )  > -1 ){
        window.location = "itms://itunes.apple.com/app/id1114650234";
      }
    } else if ( this.state.route.loc === "AlarmSupport") {
      if ( !this.props.params.alarmId || !this.props.params.pageName ) { return; }
      let pageId = 0;
      switch( ~~this.props.params.alarmId ){
        case 28:
        case 29:
        case 30:
        case 31: {
          pageId = "115000700073";
        }; break;
        case 13: {
          pageId = "115000744493";
        }; break;
        case 16: {
          pageId = "115000744413";
        }; break;
        case 15: {
          pageId = "115000744393";
        }; break;
        case 22: {
          pageId = "115000744693";
        }; break;
        case 23: {
          pageId = "115000744713";
        }; break;
        case 18: {
          pageId = "115000748614";
        }; break;
        case 33: {
          pageId = "115000748594";
        }; break;
        case 26: {
          pageId = "115000744373";
        }; break;
        case 11: {
          pageId = "115000744353";
        }; break;
        case 10: {
          pageId = "115000744293";
        }; break;
        case 51:
        case 52:
        case 53:
        case 37: {
          pageId = "115000744793";
        }; break;
        case 45: {
          pageId = "115000744773";
        }; break;
        case 32:
        case 39:
        case 40:
        case 41: {
          pageId = "115000744753";
        }; break;
        case 5:
        case 34: {
          pageId = "115000748654";
        }; break;
        case 55: {
          pageId = "360016461613";
        }; break;
        case 57: {
          pageId = "360022528514";
        }; break;
        case 0:
        default: {
          pageId = "";
        };break;
      }
      window.location = `https://meetflo.zendesk.com/hc/en-us/articles/${pageId}`;
    } else if ( this.state.route.loc === "Registration" ) {
      window.location = `${config.environment.app.protocol}register/${this.props.params.token1}/${this.props.params.token2}`;
    } else if ( this.state.route.loc === "Registration2" ) {
      window.location = `${config.environment.app.protocol}registration2/${this.props.params.token}`;
    }else {
      window.location = this.state.route.loc;
    }
  }
  /*
   * renderFor - Builds the JSX conditionaly on the UserAgent
   * @private
   * @returns { JSX } - A JSX set of elements & inline presentation layer data
   */
  renderFor(){
    if ( this.state.route.loc === "AppStore" && navigator.userAgent.search( /Android|iPhone|iPod/ ) < 0 ) {
      return ( <section><h3>Please visit this link in your iPhone or Android smartphone.</h3><h3>For more information, please visit <a href="https://meetflo.zendesk.com/hc/en-us/articles/230091847-Installing-the-Flo-Mobile-App">Flo Support Center</a>.</h3></section> );
    } else {
      return  ( <section> Redirecting... </section> );
    }
  }
	/**
   * React Lifecycle: render - Is fired when the <Redirect /> component is leveraged to draw to the screen.
   */
  render(){
    return this.renderFor();
  }
}

export default Redirect;
