"use strict";

import React, { Component } from "react";
import { connect }          from "react-redux";
import Setting              from "actions/setting";
import User                 from "actions/user";
import Authentication       from "actions/authentication";
import Dialog               from "elements/Dialog";
import TableList            from "elements/TableList";
import {Helmet}             from "react-helmet";

/**
 * Settings -> Contacts Component
 */
export class Settings_Contacts extends Component {
  constructor(props){
    super(props);

		this.headers = {
		  type: "Type",
      firstName: { display: "Name", editable: true },
      lastName:{ display: "", editable: true },
      email: { display: "E-mail Address", editable:true },
      phoneMobile: { display: "Phone", editable: true }
		};

    this.state = {
      passValue: "",
      editableContact: {
        originalEmail: "",
        type: "",
        firstName: "",
        lastName: "",
        email: "",
        phoneMobile: ""
      }
    };
  }

  componentDidMount() {
    Setting.hydrateStore();
    User.hydrateStore();
  }

  componentWillReceiveProps( nextProps ) {
    if(!this.refs.table.refs[ "row-0" ].state.editMode) {
      const user = nextProps.user;

      this.setState({
        editableContact: {
          type: user.email ? "Owner" : "",
          firstName: user.firstName,
          lastName: user.lastName,
          originalEmail: user.email,
          email: user.email,
          phoneMobile: user.phoneMobile
        }
      });
    }
    //this.setState( { ...nextProps } );
  }

  onEdit = proxy => {
    this.setState({
      editableContact: {
        type: "Owner",
        firstName: this.state.editableContact.firstName,
				lastName: this.state.editableContact.lastName,
        originalEmail: this.state.editableContact.originalEmail,
        email: this.state.editableContact.email,
        phoneMobile: this.state.editableContact.phoneMobile,
				[ proxy.target.name ]: proxy.target.value
      }
    });
  }

  onSubmit = postSubmit => {
    const contact = this.state.editableContact;

    if (contact.phoneMobile !== this.props.user.phoneMobile || contact.email !== this.props.user.email) {
      //validate email is an email
      if ( !/^(?=.*@.*\..*).*$/.test(contact.email) ) {
        new Dialog( "FloModal", "error" ).ok( <div className="inner-wrapper">Please enter a valid email address.</div>);
        return;
      }
      //validate phone is a phone
      if ( !/^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i.test(contact.phoneMobile) || !contact.phoneMobile) {
        new Dialog( "FloModal", "error" ).ok( <div className="inner-wrapper">Please enter a valid phone number</div> );
        return;
      }
      new Dialog( "FloModal", "warning" ).confirmInput( ( <div className="inner-wrapper">To change your email or phone we must verify you are the account holder. Please enter your password for verification:</div> ), {
          inputType       : "password",
          inputName       : "password",
          inputPlaceholder: "Password",
          inputLabel      : "Password",
          inputPattern    : ".*\S.*",
          inputRequired   : true
        })
        .then( value => {
          Authentication
            .validatePassword(contact.originalEmail, value)
            .then(result => {
              if(result.is_valid == true) {
                const phoneMobile = (contact.phoneMobile.trim().charAt(0) === '+') ? contact.phoneMobile.trim() : `+${contact.phoneMobile.trim()}`;
                User.setUserData({
                  firstName: contact.firstName,
                  lastName: contact.lastName,
                  //email: contact.email,
                  phoneMobile,
                }).then(() => {
                  this.setState({
                    editableContact: { ...contact, originalEmail: contact.email, phoneMobile }
                  });
                  postSubmit();
                })
                .catch(error => {
                  new Dialog("FloModal", "error")
                  .ok(<div className="inner-wrapper">Please enter a valid phone number with a valid country code.</div>)
                });
              }
            })
            .catch((error) => {
              new Dialog("FloModal", "error")
                .ok(<div className="inner-wrapper">Wrong password, try again.</div>)
              }
            );
        })
        .catch(() =>
          new Dialog("FloModal", "error")
            .ok(<div className="inner-wrapper">Please enter the password.</div>)
        );
    } else {
      User
        .setUserData({ firstName: contact.firstName, lastName: contact.lastName })
        .then(() => postSubmit());
    }
  }

  render() {
    return (
      <section className="container settings-contacts">
        <Helmet>
          <title>Flo - Contacts</title>
        </Helmet>

        <div className="row">
          <div className="col-12 bottom-margin">
            <div className="card">
              <div className="card-header">
                Contacts
              </div>
              <div className="card-body">
                <TableList
                  columns={ this.headers }
                  items={ [ this.state.editableContact ] }
                  onEdit={ this.onEdit }
                  onSubmit={ this.onSubmit }
                  ref="table"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default connect(store =>
	({
    user: {
      ...store.user
    }
	})
)(Settings_Contacts);
