// MQTT
/**
 * Device List has been recieved
 */
export const RECEIVE_DEVICE_LIST = "RECEIVE_DEVICE_LIST";
/**
 * A device has been updated
 */
export const UPDATE_DEVICE = "UPDATE_DEVICE";
export const SET_CURRENT_DEVICE = "SET_CURRENT_DEVICE";

// AUTHENTICATION
/**
 * Token has been recieved from the API
 */
export const AUTH_RECEIVE_TOKEN = "AUTH_RECEIVE_TOKEN";
/**
 * Login action has started
 */
export const AUTH_LOGIN_START = "AUTH_LOGIN_START";
/**
 * Login action has stopped
 */
export const AUTH_LOGIN_STOP = "AUTH_LOGIN_STOP";
/**
 * Login action has failed
 */
export const AUTH_LOGIN_FAILURE = "AUTH_LOGIN_FAILURE";
/**
 * Login Error messages are cleared
 */
export const AUTH_LOGIN_CLEAR_ERR = "AUTH_LOGIN_CLEAR_ERR";
/**
 * Force authentication token to expire
 */
export const AUTH_EXPIRE_TOKEN = "AUTH_EXPIRE_TOKEN";

// CONSUMPTION ( USAGE )
/**
 * Update consumption goal
 */
export const UPDATE_CONSUMPTION_GOAL = "UPDATE_CONSUMPTION_GOAL";
/**
 * Update consumption by fixture
 */
export const UPDATE_CONSUMPTION_BY_FIXTURE = "UPDATE_CONSUMPTION_BY_FIXTURE";

export const UPDATE_MEASUREMENTS = "UPDATE_MEASUREMENTS";
export const UPDATE_DAILY_GOAL_TOTALS = "UPDATE_DAILY_GOAL_TOTALS";
export const UPDATE_SELECTED_DEVICE_INDEX = "UPDATE_SELECTED_DEVICE_INDEX";
export const UPDATE_METRICS = "UPDATE_METRICS";

// SETTINGS
/**
 * Update a specific setting
 */
export const UPDATE_SETTING = "UPDATE_SETTING";
export const UPDATE_ALARM_SETTINGS = "UPDATE_ALARM_SETTINGS";
export const UPDATE_HOME_PROFILE_FIELDS = "UPDATE_HOME_PROFILE_FIELDS";
export const LOADING_HOME_PROFILE_FIELDS = "LOADING_HOME_PROFILE_FIELDS";

// ALERTS
/**
 * Update the alerts lists
 */
export const ALERT_LOG_UPDATE = "ALERT_LOG_UPDATE";
export const ALERT_LOG_UPDATING = "ALERT_LOG_UPDATING";
export const ALERT_LOG_ERROR = "ALERT_LOG_ERROR";
export const FETCH_PENDING_ALERTS_SUCCESS = "FETCH_PENDING_ALERTS_SUCCESS";
export const FETCH_CLEARED_ALERTS_SUCCESS = "FETCH_CLEARED_ALERTS_SUCCESS";

// ALARMS
export const FETCH_ALARMS_SUCCESS = "FETCH_ALARMS_SUCCESS";

// FLO DETECT

export const UPDATE_FIXTURE_DETECTION = "UPDATE_FIXTURE_DETECTION";
export const LOADING_FLO_DETECT = "LOADING_FLO_DETECT";

// USER
/**
 * Update a piece of user data
 */
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const UPDATE_LOCATION_PROFILE = "UPDATE_LOCATION_PROFILE";
export const FETCH_USER_LOCATIONS_SUCCESS = "FETCH_USER_LOCATIONS_SUCCESS";
export const UPDATE_USER_METADATA = "UPDATE_USER_METADATA";
export const FETCH_ALARM_SETTINGS_SUCCESS = "FETCH_ALARM_SETTINGS_SUCCESS";
export const UPDATE_LEAK_SENSITIVITY_SETTINGS = "UPDATE_LEAK_SENSITIVITY_SETTINGS";
export const UPDATE_LEAK_SENSITIVITY = "UPDATE_LEAK_SENSITIVITY";
export const UPDATE_SYSTEM_MODE = "UPDATE_SYSTEM_MODE";
export const UPDATE_NEXT_LOCATION = "UPDATE_NEXT_LOCATION";

// Query
export const UPDATE_QUERY_METADATA = "UPDATE_QUERY_METADATA";

// AUTHORIZATION
export const AUTHORIZATION_RETRIEVE_CLIENT_INFO_AND_SCOPES = "AUTHORIZATION_RETRIEVE_CLIENT_INFO_AND_SCOPES";

export const SUBSCRING_TO_FLO_PROTECT = "SUBSCRING_TO_FLO_PROTECT";

export const SUBSCRIBED_TO_FLO_PROTECT = "SUBSCRIBED_TO_FLO_PROTECT";

export const UPDATE_SUBSCRIPTION_INFO = "UPDATE_SUBSCRIPTION_INFO";

export const UPDATE_PLAN_INFO = "UPDATE_PLAN_INFO";

export const UPDATE_USER_TOKEN_INFO = "UPDATE_USER_TOKEN_INFO";

export const UPDATE_SOURCE_ID = "UPDATE_SOURCE_ID";

export const UPDATE_COUPON_INFO = "UPDATE_COUPON_INFO";

export const UPDATE_DAILY_GOAL_CONSUMPTION = "UPDATE_DAILY_GOAL_CONSUMPTION";

export const UPDATE_FLO_DETECT_DATA = "UPDATE_FLO_DETECT_DATA";

export const FLO_PROTECT_SUBSCRIPTION_FAILED = "FLO_PROTECT_SUBSCRIPTION_FAILED";

export const UPDATE_FLOW_EVENTS = "UPDATE_FLOW_EVENTS";

export const INITIALIZE_FLOW_EVENTS_NEW_PAGE = "INITIALIZE_FLOW_EVENTS_NEW_PAGE";

//FIRESTORE
export const UPDATE_DEVICE_STATUS = "UPDATE_DEVICE_STATUS";
export const ADD_DEVICE_STATUS_FIRESTORE_REFERENCE = "ADD_DEVICE_STATUS_FIRESTORE_REFERENCE";

export const UPDATE_EMAIL_SUBSCRIPTIONS = "UPDATE_EMAIL_SUBSCRIPTIONS";

export const UPDATE_SUBSCRIPTION_STATUS = "UPDATE_SUBSCRIPTION_STATUS";

export const UPDATE_INSURANCE_LETTER_STATUS = "UPDATE_INSURANCE_LETTER_STATUS";

export const UPDATE_INSURANCE_LETTER_DOWNLOAD_INFO = "UPDATE_INSURANCE_LETTER_DOWNLOAD_INFO";

export const REDEEM_INSURANCE_LETTER = "REDEEM_INSURANCE_LETTER";

export const UPDATE_FIRESTORE_DATA = "UPDATE_FIRESTORE_DATA";

export const UPDATE_LOADING_FILE = "UPDATE_LOADING_FILE";

// SUBSCRIPTION
export const UPDATE_PAYMENT_METHOD_FORM_INFO = "UPDATE_PAYMENT_METHOD_FORM_INFO";
export const UNSUBSCRIBING_FROM_FLO_PROTECT = "UNSUBSCRIBING_FROM_FLO_PROTECT";
export const UNSUBSCRIBED_FROM_FLO_PROTECT = "UNSUBSCRIBED_FROM_FLO_PROTECT";
export const FLO_PROTECT_UNSUBSCRIPTION_FAILED = "FLO_PROTECT_UNSUBSCRIPTION_FAILED";
export const CLEAN_PAYMENT_FORM_STATE = "CLEAN_PAYMENT_FORM_STATE";

// HEALTH TEST
export const FETCHING_HEALTH_TEST_DATA = "FETCHING_HEALTH_TEST_DATA";
export const FETCHED_HEALTH_TEST_DATA_SUCCESS = "FETCHED_HEALTH_TEST_DATA_SUCCESS";
export const FETCHED_HEALTH_TEST_DATA_ERROR = "FETCHED_HEALTH_TEST_DATA_ERROR";
export const CLEAR_HEALTH_TEST_DATA = "CLEAR_HEALTH_TEST_DATA";
