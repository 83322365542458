"use strict";

import {
  UPDATE_USER_DATA,
  UPDATE_EMAIL_SUBSCRIPTIONS,
  UPDATE_SUBSCRIPTION_STATUS
} from "constants/action";
import { Authenticate } from "actions/authentication";
import SubscriptionStatus from 'constants/SubscriptionStatus';
import Store from "store";
import EmailPreferencesAPI from "api/emailPreferences";
import WeeklyReportAPI from "api/weeklyReport";
import UserAction from "actions/user";

const updateSubscriptionStatus = data => ({
  type: UPDATE_SUBSCRIPTION_STATUS,
  data
});

const updateEmailsSubscriptions = data => ({
  type: UPDATE_EMAIL_SUBSCRIPTIONS,
  data
});

const updateUserData = data => ({
  type: UPDATE_USER_DATA,
  data: data
});

class _EmailPreferences {
  @Authenticate()
  hydrateStore() {
    return Promise.all([
      UserAction.getUserData(true),
      EmailPreferencesAPI.getAllEmails()
    ]).then(([user, { data: emails }]) => {
      const userId = user.id;

      return Promise.all([
        EmailPreferencesAPI.getUserPreferences(userId),
        WeeklyReportAPI.retrieveWeeklySubscriptionByUserId(userId)
      ]).then(([subscriptionInfo, weeklyReportSubscriptionInfo]) => {
        const subscriptionsExists = !_.isEmpty(subscriptionInfo);
        const weeklyReportPreference = {
          description: "Weekly Report",
          email_id: "weekly_report",
          name: "Weekly Report",
          subscribed: !_.isEmpty(weeklyReportSubscriptionInfo)
        };
        const emailsPreferences = emails.map(email => {
          const subscribed = !subscriptionsExists || (subscriptionsExists && subscriptionInfo.subscriptions[email.email_id]);

          return {
            ...email,
            subscribed
          }
        });

        Store.dispatch(updateEmailsSubscriptions([ ...emailsPreferences, weeklyReportPreference ]));
        Store.dispatch(updateUserData(user));
      });
    });
  }

  @Authenticate
  updateUserPreferences(userId, subscriptions) {
    return EmailPreferencesAPI
      .updateUserPreferences(userId, subscriptions);
  }

  @Authenticate
  subscribeWeeklyReport(userId, locationId) {
    return WeeklyReportAPI
      .subscribe(userId, locationId)
      .then(() => {
        Store.dispatch(updateSubscriptionStatus(SubscriptionStatus.SUBSCRIBED));
      });
  }

  @Authenticate
  unsubscribeWeeklyReport(userId, locationId) {
    return WeeklyReportAPI
      .unsubscribe(userId, locationId)
      .then(() => {
        Store.dispatch(updateSubscriptionStatus(SubscriptionStatus.UNSUBSCRIBED));
      });
  }

  @Authenticate
  unsubscribeByEmbeddedLink(userId, locationId, unsubscribeToken, accessToken) {
    Store.dispatch(updateSubscriptionStatus(SubscriptionStatus.UNSUBSCRIBING));

    return WeeklyReportAPI
      .unsubscribeByEmbeddedLink(userId, locationId, unsubscribeToken, accessToken)
      .then(() => {
        Store.dispatch(updateSubscriptionStatus(SubscriptionStatus.UNSUBSCRIBED));
      })
      .catch(() => {
        Store.dispatch(updateSubscriptionStatus(SubscriptionStatus.UNSUBSCRIBE_FAILURE));
      });
  }
};
/**
 * @ignore
 */
export const EmailPreferences = new _EmailPreferences();
export default EmailPreferences;
