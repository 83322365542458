"use strict";

import {
  UPDATE_LOCATION_PROFILE,
  FETCH_USER_LOCATIONS_SUCCESS,
  RECEIVE_DEVICE_LIST,
  UPDATE_QUERY_METADATA,
  UPDATE_LEAK_SENSITIVITY_SETTINGS,
  UPDATE_SYSTEM_MODE,
  UPDATE_USER_DATA,
  UPDATE_DAILY_GOAL_TOTALS
} from "constants/action";
import LocationSettingAPI from "api/setting/location";
import { Authenticate } from "actions/authentication";
import FloDetect from "./floDetect";
import InsuranceLetter from "./insuranceLetter";
import Consumption from "./consumption";
import Device from "./device";
import Setting from "./setting";
import UserAPI from 'api/user';
import Store from "store";
import axios from "axios";
import User from "../api/user";
import _ from "lodash";
import Alert from "./alert";
import UserAction from "./user";
import SafeQuery from "./common/SafeQuery";
import SystemMode from "constants/SystemMode";
import FloProtectAction from './floprotect';

const twentyFourHours = 86400;

const updateLocationProfile = locationProfileData => {
  const data =  {
    ...locationProfileData,
    location: {
      ...locationProfileData.location,
      country: (locationProfileData.location.country || "").toLowerCase(),
      state: (locationProfileData.location.state || "").toUpperCase()
    }
  };
  return {
    type: UPDATE_LOCATION_PROFILE,
    data
  };
};

const fetchUserLocationsSuccess = (locations, current) => ({
  type: FETCH_USER_LOCATIONS_SUCCESS,
  locations,
  current
});

const updateFetched = (query) => ({
  type: UPDATE_QUERY_METADATA,
  query,
  data: { fetched: true }
});

const receiveDeviceList = devices => ({
  type: RECEIVE_DEVICE_LIST,
  devices
});

const updateLeakSensitivitySettings = settings => ({
  type: UPDATE_LEAK_SENSITIVITY_SETTINGS,
  settings
});

const updateSystemMode = (locationData) => ({
  type: UPDATE_SYSTEM_MODE,
  locationData,
});

const updateUserData = data => ({
  type: UPDATE_USER_DATA,
  data: data
});

const updateDailyGoalsTotal = unitSystem => ({
  type: UPDATE_DAILY_GOAL_TOTALS,
  unitSystem
});

/**
 * LocationProfile Actions module
 */
class LocationProfile extends SafeQuery {

  /**
   * Hydrates the store with user location profile data
   */
  @Authenticate()
  hydrateStore(forceReload = false){
    const userStore = Store.getState().user;
    if (!forceReload && userStore.locations.length > 0) {
      return Promise.resolve(userStore.currentLocation);
    }

    // Get all locations for current user
    const locationsPromise = UserAction.getUserData(true);

    // Get countries list
    const countriesPromise = LocationSettingAPI.fetchCountries();

    return axios.all([
      countriesPromise,
      locationsPromise
    ]).then( ([ countries, userData ]) => {
      const locations = userData.locations;

      const location = this._getCurrentLocation(locations, userStore.currentLocation.id || userStore.nextCurrentLocation);

      const country = (location.country || "").toLowerCase();
      const state = (location.state || "").toUpperCase();
      const statesPromise = this.getCountryStates(country);
      const timezonesPromise = this.getTimeZones(country, state);

      Store.dispatch(fetchUserLocationsSuccess(locations, location));
      Store.dispatch(updateLeakSensitivitySettings(userData.alarmSettings));
      Store.dispatch(updateUserData(userData));
      Store.dispatch(updateDailyGoalsTotal(userData.unitSystem));

      const fetchLocationByIdQuery = this.createQuery('Location.fetchLocationById', LocationSettingAPI.fetchLocationById);
      const locationByIdPromise = fetchLocationByIdQuery(location.id);
      return axios.all([
        statesPromise,
        timezonesPromise,
        locationByIdPromise
      ]).then( ([ states, timezones, locationData ]) => {
        Store.dispatch(updateLocationProfile({
          location,
          countries,
          states,
          timezones
        }));

        Store.dispatch(receiveDeviceList(this._getDeviceList(locationData.devices)));
        Store.dispatch(updateFetched('User.getUserById'));
        const macAddress = Store.getState().device.currentMacAddress;
        if (macAddress) {
          Device.subscribeDeviceStatus(macAddress);
        }
        Consumption.getDailyGoalConsumption();
        Consumption.getMeasurements();
        return location;
      });
    })
  }

  _getDeviceList(devices) {
    return devices.map(device => ({
      id: device.id,
      device_id: device.macAddress,
      location_id: location.id,
      deviceModel: device.deviceModel,
      deviceType: device.deviceType,
      nickname: device.nickname,
      isLearning: !device.learning || !device.learning.outOfLearningDate
    }));
  }

  changeCurrentLocation(locationId) {
    const userStore = Store.getState().user;
    const locations = userStore.locations;
    const newCurrentLocation = locations.find(loc => loc.id === locationId);
    if (!newCurrentLocation) {
      console.error("Location not found");
    } else {
      const prevMacAddress = Store.getState().device.currentMacAddress;

      LocationSettingAPI.fetchLocationById(newCurrentLocation.id)
        .then(locationData => {
          // update store
          Store.dispatch(fetchUserLocationsSuccess(locations, newCurrentLocation));
          Store.dispatch(receiveDeviceList(this._getDeviceList(locationData.devices)));
          Store.dispatch(updateLocationProfile({ location: newCurrentLocation }));

          // Reload data for new location
          FloDetect.retrieveLatestInLastHours(
            twentyFourHours, (newCurrentLocation.timezone !== '') ? newCurrentLocation.timezone : undefined
          );
          FloProtectAction.getLocationSubscriptionInfo().then(() => InsuranceLetter.loadInsuranceLetter());
          Consumption.getDailyGoalConsumption();
          Alert.hydrateStore();
          Setting.hydrateStore();
          Consumption.getMeasurements();

          const macAddress = Store.getState().device.currentMacAddress;
          // set active device
          Device.setActiveDevice(prevMacAddress, macAddress);
        })
        .catch(error => console.log(error));
    }
  }

  _getCurrentLocation(locations, currentLocationId) {
    let current;
    // Use current selected location if we already have one
    if (currentLocationId) {
      current = locations.find(loc => loc.id === currentLocationId);
    } else {
      current = locations.find(loc => loc.residenceType === 'primary');
    }
    if (current) {
      return current;
    } else {
      if (locations.length === 1) {
        return locations[0];
      } else {
        const validLocations = locations.filter(loc => loc.nickname || loc.address);
        const ordered = _.orderBy(validLocations, l => l.nickname || l.address);
        return ordered[0];
      }
    }
  }

  @Authenticate()
  hydrateDropdowns(currentLocation, dropdownsSelectedData) {
    if(dropdownsSelectedData.state) {
      const location = {
        ...currentLocation,
        state: dropdownsSelectedData.state,
        timezone: ""
      };

      this
        .getTimeZones(currentLocation.country, dropdownsSelectedData.state)
        .then( timezones => {
          Store.dispatch( updateLocationProfile( { location,  timezones } ) );
        });

    } else if(dropdownsSelectedData.country) {

      const location = {
        ...currentLocation,
        country: dropdownsSelectedData.country,
        state: "",
        timezone: ""
      };

      axios.all([
        this.getCountryStates(dropdownsSelectedData.country),
        this.getTimeZones(dropdownsSelectedData.country)
      ]).then( ([ states, timezones ]) => {
        Store.dispatch( updateLocationProfile( { location, states, timezones } ) );
      });
    }
  }


  @Authenticate()
  saveLocationProfile(locationId, data) {
    return LocationSettingAPI
      .saveLocationById(locationId, data)
      .then(() => {
        Store.dispatch(updateLocationProfile({ location: data }));
        this.hydrateStore(true);
      });
  }


  @Authenticate()
  getCountryStates( country ) {
    return country ? LocationSettingAPI.fetchStates(country) : [];
  }

  @Authenticate()
  getTimeZones( country, state ){
    return country ? LocationSettingAPI.fetchTimezones( country, state ) : [];
  }

  /**
   * Set system mode for this location
   * @param { number } mode - the ID of the system mode to set for this location
   * @return { Promise } - No Values passed
   */
  @Authenticate()
  setSystemMode(mode) {
    return this.hydrateStore()
      .then(location => {
        const updatePromise = LocationSettingAPI.setSystemModeData(location.id, SystemMode.fromId(mode).name);
        return Promise.all([
          updatePromise,
          location
        ]);
      })
      .then(([updateData, locationData]) => LocationSettingAPI.fetchLocationById(locationData.id, false))
      .then((updatedLocationData) => Store.dispatch(updateSystemMode(updatedLocationData)))
      .catch(error => console.log(error));
  }
  /**
   * Set devices for this location to sleep for X minutes
   * @param { numer } previousMode - Mode prior to swtiching to sleep mode
   * @param { number } minutes - timeout for mode to switch back automatically
   * @return { Promise } - No Values passed
   */
  @Authenticate()
  setSystemSleep(previousMode, minutes) {
    return this.hydrateStore()
      .then(location => {
        const updatePromise = LocationSettingAPI.setSystemModeData(location.id, SystemMode.SLEEP.name, parseInt(minutes, 10), SystemMode.fromId(previousMode).name);
        return Promise.all([
          updatePromise,
          location
        ]);
      })
      .then(([updateData, locationData]) => LocationSettingAPI.fetchLocationById(locationData.id, false))
      .then((updatedLocationData) => Store.dispatch(updateSystemMode(updatedLocationData)))
      .catch(error => console.log(error));
  }
}

export default new LocationProfile();
