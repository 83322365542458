"use strict";

import React, { Component } from "react";
import { connect }          from "react-redux";
import Authentication       from "actions/authentication";
import Dialog               from "elements/Dialog";
import LoginForm            from "elements/LoginForm";
import Footer               from "layout/Footer";
import PropTypes from 'prop-types';
import { getFormValues, getFormSyncErrors } from 'redux-form'

/**
 * Authorization View
 */
export class Login extends Component {

  // Access store by defining context types
  static contextTypes = {
    store: PropTypes.object,
  }

  /**
   * React Lifecycle: Initilizes the component and sets the initial state
   * @param { Object } props - Initial incomming properties on the component
   */
	constructor( props ) {
    super(props);
  }

  forgotPassModal = event => {
    // Get the redux store from context
    const { store } = this.context;
    const state = store.getState();
    const errors = getFormSyncErrors('login-form')(state);

    if(errors.email) {
      new Dialog( "FloModal", "error" ).ok( "Please enter a valid email." );
    } else {
      const values = getFormValues('login-form')(state);
      const email = values.email;
      const msg = (<span>Password Reset: Are you sure?<br />This will send password reset instructions to: { email } </span>);

      new Dialog( "FloModal", "warning" ).confirm( msg, { yesBtn: "Continue", noBtn: "Cancel" } )
        .then( () => {
          Authentication
            .resetPasswordRequest( email )
            .then( success => {
              new Dialog( "FloModal", "warning" ).ok( success.message );
            });
        });
    }
  }

  /**
   * Login action
   */
  login = formValues => {
    const redirectPath = (this.props.location.state) ?
      `${this.props.location.state.nextPathname}${this.props.location.state.nextSearch}` :
      `${this.props.route.redirectPath}${this.props.location.search}`;

    const mixpanel = this.context.mixpanel;

    // Impersonation
    const subDomain = location.host.match(/^[\w-]+/)[0];
    if ( subDomain.search( /user|local/ ) > -1 ) {
      Authentication.requestToken( formValues.email, formValues.password, redirectPath, mixpanel);
    } else {
      Authentication.impersonate( formValues.email, formValues.password, subDomain, redirectPath, mixpanel);
    }

    setTimeout( Authentication.clearErrorMessages, 4000 );
  }

	/**
   * React Lifecycle: render - Is fired when the <AlertNotification /> component is leveraged to draw to the screen.
   * Calls: {@link  renderDisplayText}
   * @return { JSX } - a JSX Object
   */
  render() {
    const loginErr = this.props.authentication ? this.props.authentication.loginErr : null;

    return (
      <section className="login">
        <LoginForm
          onSubmit={ this.login }
          handleForgotPassword={ this.forgotPassModal }
          loginErr={ loginErr }
        />
        <div id="FloModal"></div>
        <Footer />
      </section>
    )
  }
}

Login.contextTypes = {
  mixpanel: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired
};

export default connect( store =>
  ({
    authentication: {
      ...store.authentication
    }
  })
)( Login );
