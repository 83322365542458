"use strict";

import React, { PureComponent } from "react";
import Toggle                   from "elements/Toggle";
import TelemetryWidget          from "elements/TelemetryWidget";
import LeakDetectionCard        from 'elements/home/LeakDetectionCard';
import deviceModels             from 'constants/deviceModels';
import Device                   from 'actions/device';
import DeviceStatus             from 'constants/DeviceStatus';
import { Converter, Unit }      from '../../../constants/UnitSystem';
import NoDevicesCard            from '../NoDevicesCard';
import _                        from 'lodash';

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge } from 'reactstrap';

const toggleValues = [ "on", "off" ];

export default class CurrentDeviceStatsCard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
    }
  }

  startAnimatingDeviceState = () => {
    this.refs.deviceState.startAnimating();
  }

  stopAnimatingDeviceState = () => {
    this.refs.deviceState.stopAnimating();
  }

  toggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  handleDeviceClick = (macAddress) => {
    Device.changeCurrentDevice(macAddress);
  };

  render() {
    const { device, isLocationInfoLoading, deviceList, unitSystem } = this.props;

    const dropdownItems = deviceList
        .filter(d => d.id !== device.id)
        .map(d => (
          <DropdownItem onClick={() => this.handleDeviceClick(d.device_id)} key={`device-${d.id}`}>
            { d.nickname || deviceModels.getDeviceTypeLabel(d.deviceModel, d.deviceType) }
          </DropdownItem>
        ));

    if (!isLocationInfoLoading && deviceList.length < 1) {
      return <NoDevicesCard />;
    }
    const statusClass = device.status ? device.status.replace(/\s/g, "-") : "";
    const statusText = device.status ? device.status.replace(/device/g, "") : "";

    return (
      <div className="card device-stats-card h-100">
        <div className="card-body">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 mx-auto text-center">
              <div className="row">
                <div className="col-6 col-sm-6 col-lg-3 text-center">
                  <Dropdown
                    isOpen={this.state.dropdownOpen}
                    toggle={this.toggle}
                    className="device-dropdown"
                    disabled={!device.id}
                  >
                    <DropdownToggle caret>
                      { device.id && (device.nickname || deviceModels.getDeviceTypeLabel(device.deviceModel, device.deviceType)) }
                      { !device.id && "Loading... " }
                    </DropdownToggle>
                    { device.id && dropdownItems.length > 0 &&
                      <DropdownMenu>
                        { dropdownItems }
                      </DropdownMenu>
                    }
                  </Dropdown>
                </div>
                <div className="col-6 col-sm-6 col-lg-9 device-status">
                  { (device.status !== DeviceStatus.NO_DEVICE) &&
                    <div>
                      <Badge pill className={`device-status-icon ${statusClass}`}>{ " " }</Badge>
                      <span className="status-text">{ statusText }</span>
                    </div>
                  }
                </div>
              </div>
              <div className="row align-items-center telemetry-controls">
                <div className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 mx-auto text-center">
                  <TelemetryWidget
                    icon="Faucet"
                    context="Flow"
                    unit={Unit.displayShortFlowUnit(unitSystem)}
                    unitToolTip={Unit.displayLongFlowUnit(unitSystem)}
                    value={ Converter.convertFromGallons(this.props.wf, unitSystem) }
                  />
                </div>
                <div className="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4 mx-auto text-center">
                  <TelemetryWidget
                    icon="PSI-Gauge"
                    context="Pressure"
                    unit={_.toUpper(Unit.displayShortPressureUnit(unitSystem))}
                    unitToolTip={Unit.displayLongPressureUnit(unitSystem)}
                    value={ Converter.convertFromPSI(this.props.p, unitSystem) }
                  />
                </div>
                <div className="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4 mx-auto text-center">
                  <TelemetryWidget
                    icon="Thermometer"
                    context="Temp"
                    unit={Unit.displayShortTempUnit(unitSystem)}
                    unitToolTip={`Degrees ${Unit.displayLongTempUnit(unitSystem)}`}
                    value={ Converter.convertFromFahrenheit(this.props.t, unitSystem) }
                  />
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mx-auto text-center">
                  <Toggle
                    ref="deviceState"
                    nodes={ toggleValues }
                    controlName="Water"
                    className="state"
                    active={ this.props.valveState }
                    onToggle={ this.props.onSetValveState }
                    disabled={ this.props.disabled }
                    message="Please confirm you want the Flo device to turn ${newState} your water"
                    animatingClass="transitioning"
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 device-state-container">
              <LeakDetectionCard
                healthTestAlarmsSensitivity={ this.props.healthTestAlarmsSensitivity }
                healTestAlarmSelectedSensitivityDetails={ this.props.healTestAlarmSelectedSensitivityDetails }
                lastTimeHealthTestRan={ this.props.lastTimeHealthTestRan }
                dripLogoSize={ this.props.dripLogoSize }
                showHealthTestInfo={ this.props.showHealthTestInfo }
                healthTestAlarmsSensitivityLevels={ this.props.healthTestAlarmsSensitivityLevels }
                onHealthTestAlarmsSensitivity={ this.props.onHealthTestAlarmsSensitivity }
                onToggleShowHealthTestInfo={ this.props.onToggleShowHealthTestInfo }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
