"use strict";

import React, { Component }       from "react";
import { Provider }               from "react-redux";
import {
  Route,
  Router,
  IndexRedirect,
  IndexRoute,
  Redirect
}                                 from "react-router";
import mixpanel                   from 'mixpanel-browser';
import MixpanelProvider           from 'react-mixpanel';

import config                     from "config";
import Authentication             from "actions/authentication";
import Device											from "actions/device";

import App                        from "components/AppRoot";
import InvalidPath                from "components/InvalidPath";
import RedirectComponent          from "components/Redirect";

import Login                      from "views/Login";
import Home                       from "views/Home";
import PasswordReset              from "views/PasswordReset";
import Alerts_Log                 from "views/Alerts_Log";
import FloProtect                 from "views/FloProtect";
import EmailUnsubscribed          from "views/EmailUnsubscribed";
import Settings_UserProfile       from "views/Settings_UserProfile";
import Settings_Notifications     from "views/Settings_Notifications";
import Settings_Contacts          from "views/Settings_Contacts";
import Settings_Devices           from "views/Settings_Devices";
import Settings_ConsumptionGoals  from "views/Settings_ConsumptionGoals";
import Settings_HomeProfile       from "views/Settings_HomeProfile";
import Settings_HealthTest        from "views/Settings_HealthTest";
import Usage                      from "views/Usage";
import AuthorizationConfirmation  from "views/AuthorizationConfirmation";
import FloDetectValidation        from "views/FloDetectValidation";
import AccountCreationLanding     from "views/AccountCreationLanding";
import LocationConfirmation       from "views/LocationConfirmation";
import AccountCreation            from "views/AccountCreation";
import EmailConfirmation          from "views/EmailConfirmation";
import EmailConfirmed             from "views/EmailConfirmed";
import InsuranceLetter            from "views/InsuranceLetter";
import Referrals                  from "views/Referrals";
import DeductibleGuarantee        from "views/DeductibleGuarantee";

import { History, Store }         from "store";
import {StripeProvider}           from 'react-stripe-elements';

/**
 * Enter View Method, determines whether to show the views, or redirect to login with nextPathName set to intended URI
 * @param { Object } nextState - current views router state
 * @param { Object } replace - replace the current state for the views router
 * @param { function } next - callback to determine an async action is complete
 * @return { undefined } - returns nothing
 */
const onRootEnter = ( nextState, replace, next ) => {
Authentication
  .isLoggedIn()
  .then( () => {
    next();
  })
  .catch( err => {
    const subDomain = location.host.match(/^[\w-]+/)[0];
    replace({
      pathname: subDomain.search( /user|local/ ) > -1 ? "/login" : "/impersonate",
      state: {
        nextPathname: nextState.location.pathname,
        nextSearch: nextState.location.search
      }
    });
    next();
  });
}


const onAuthorizationEnter = ( nextState, replace, next ) => {
  Authentication
    .isLoggedIn()
    .then( () => {
      replace({
        pathname: `/authorization/confirmation`,
        query: nextState.location.query
      });
      next();
    }).catch(err => next());
}

const onFloProtectEnter = ( nextState, replace, next ) => {
  Authentication
    .getUserTokenInfo()
    .then(tokenInfo => {
      if(typeof tokenInfo.impersonator_user_id !== 'undefined') {
        replace({
          pathname: '/',
          state: nextState
        });
      }
      next();
    }).catch(err => {
      replace({
        pathname: '/',
        state: nextState
      });
      next();
    });
}

const stripePublicKey = config.environment.stripe.publicKey;

const mixpanelPublicKey = config.environment.mixpanel.publicKey;
mixpanel.init(mixpanelPublicKey);

export default class FloRoutes extends Component {

  /**
   * @desc Listed Routes ( Not Properties of 'render' method )
   * @property /privacy-policy                            - https://meetflo.zendesk.com/hc/en-us/articles/230425728-Privacy-Policies
   * @property /terms-of-service                          - https://meetflo.zendesk.com/hc/en-us/articles/230089687-Terms-of-Service
   * @property /license-agreement                         - https://meetflo.zendesk.com/hc/en-us/articles/230425668-End-User-License-Agreement
   * @property /support/alarms/:alarmId/:page             - https://meetflo.zendesk.com/hc/en-us/articles/:pageId
   * @property /register/:token1/:token2                  - floapp://register/{token1}/{token2}
   * @property /register2/:token                          - floapp://registration2/{token}
   * @property /mobile/download                           - User Agend Detect -> iOS Store || Android Play Store for "Flo Technologies"
   * @property /mobile/support                            - http://flocloud-support.s3-website-us-west-2.amazonaws.com/
   * @property /mobile/support/android                    - http://flocloud-support.s3-website-us-west-2.amazonaws.com/
   * @property /mobile/support/android/legal              - http://flocloud-support.s3-website-us-west-2.amazonaws.com/terms.html
   * @property /mobile/support/ios                        - http://flocloud-support.s3-website-us-west-2.amazonaws.com/
   * @property /mobile/support/ios/legal                  - http://flocloud-support.s3-website-us-west-2.amazonaws.com/terms.html
   * @property /app/home                                  - floapp://dashboard
   * @property /app/pairing                               - floapp://pairing
   * @property /app/help                                  - floapp://help
   * @property ---
   * @property /login                             - Login View
   * @property /usage                             - Usage
   * @property /alerts                            - Alerts Log View
   * @property /settings                          - Redirects to: /settings/user-profile
   * @property /settings/user-profile             - User Profile Settings
   * @property /settings/notifications            - Notification Delivery Rule Settings
   * @property /settings/contacts                 - Account Contacts Settings
   * @property /settings/devices                  - ICD Device Settings & QR Code Display
   * @property /settings/consumption-goals        - Consumption Goals Settings
   * @property /settings/home-profile             - Home Profile / Location settings
   * @property /settings/health-test              - Health Test
   * @property /passwordreset/:userId/:resetToken - Password reset views
   * @property /authorization                     - 3rd Party integrations authorization
   * @property /*                                 - Invalid Path URI catchall 'other'
   */
  render() {
    return (
      <Provider store={ Store }>
        <MixpanelProvider mixpanel={mixpanel}>
          <StripeProvider apiKey={stripePublicKey}>
            <Router history={ History }>
              <Route
                path="/privacy-policy"
                component={ RedirectComponent }
                loc="https://meetflo.zendesk.com/hc/en-us/articles/230425728-Privacy-Policies"
                />
              <Route
                path="/terms-of-service"
                component={ RedirectComponent }
                loc="https://meetflo.zendesk.com/hc/en-us/articles/230089687-Terms-of-Service"
                />
              <Route
                path="/license-agreement"
                component={ RedirectComponent }
                loc="https://meetflo.zendesk.com/hc/en-us/articles/230425668-End-User-License-Agreement"
                />
              <Route path="/support/alarms/:alarmId/:pageName" component={ RedirectComponent } loc="AlarmSupport" />
              <Route path="/mobile/download" component={ RedirectComponent } loc="AppStore" />
              <Route path="/mobile/support">
                <IndexRoute component={ RedirectComponent } loc="http://flocloud-support.s3-website-us-west-2.amazonaws.com/" />
                <Route path="android">
                  <IndexRoute component={ RedirectComponent } loc="http://flocloud-support.s3-website-us-west-2.amazonaws.com/" />
                  <Route path="legal" component={ RedirectComponent } loc="http://flocloud-support.s3-website-us-west-2.amazonaws.com/terms.html" />
                  <Route path="contact" component={ RedirectComponent } loc="https://meetflo.zendesk.com/hc/en-us/requests/new" />
                </Route>
                <Route path="ios">
                  <IndexRoute component={ RedirectComponent } loc="http://flocloud-support.s3-website-us-west-2.amazonaws.com/" />
                  <Route path="legal" component={ RedirectComponent } loc="http://flocloud-support.s3-website-us-west-2.amazonaws.com/terms.html" />
                  <Route path="contact" component={ RedirectComponent } loc="https://meetflo.zendesk.com/hc/en-us/requests/new" />
                </Route>
              </Route>
              <Route path="/register/:token1/:token2" component={ RedirectComponent } loc="Registration" />
              <Route path="/register2/:token" component={ RedirectComponent } loc="Registration2" />
              <Route path="/passwordreset/:userId/:resetToken" component={ PasswordReset } />

              <Route
                path="/app/home"
                component={ RedirectComponent }
                loc={ config.environment.app.protocol + "dashboard" }
                />
              <Route
                path="/app/pairing"
                component={ RedirectComponent }
                loc={ config.environment.app.protocol + "pairing" }
                />
              <Route
                path="/app/help"
                component={ RedirectComponent }
                loc={ config.environment.app.protocol + "help" }
                />
              <Route path="/impersonate" component={ Login } redirectPath="/home" />
              <Route path="/login" component={ Login } redirectPath="/home" />
              <Route path="/" component={ App } onEnter={ onRootEnter } >
                <IndexRedirect
                  to="/home"
                  />
                <Route path="home" name="home">
                  <IndexRoute
                    component={ Home }
                  />
                </Route>
                <Route path="usage" name="usage">
                  <IndexRoute
                    component={ Usage }
                  />
                </Route>
                <Route path="alerts" name="alerts">
                  <IndexRoute
                    component={ Alerts_Log }
                    />
                </Route>
                <Route path="flodetect/validation" name="flodetect/validation">
                    <IndexRoute
                      component={FloDetectValidation}
                    />
                </Route>
                <Route path="settings" name="settings">
                  <IndexRedirect
                    to="/settings/user-profile"
                    />
                  <Route
                    path="/settings/user-profile"
                    component={ Settings_UserProfile }
                    name="settings"
                    />
                  <Route
                    path="/settings/alert-settings"
                    component={ Settings_Notifications }
                    name="settings"
                    />
                  <Route
                    path="/settings/contacts"
                    component={ Settings_Contacts }
                    name="settings"
                    />
                  <Route
                    path="/settings/devices"
                    component={ Settings_Devices }
                    name="settings"
                    />
                  <Route
                    path="/settings/consumption-goals"
                    component={ Settings_ConsumptionGoals }
                    name="settings"
                    />
                  <Route
                    path="/settings/home-profile"
                    component={ Settings_HomeProfile }
                    name="settings"
                    />
                  <Route
                    path="/settings/health-test"
                    component={ Settings_HealthTest }
                    name="settings"
                    />
                </Route>
                <Route path="floprotect" name="floprotect" /*onEnter={ onFloProtectEnter }*/>
                  <IndexRoute
                    component={ FloProtect }
                  />
                  <Route
                    path="/floprotect/insurance-letter"
                    component={ InsuranceLetter }
                    name="insurance-letter"
                  />
                  <Route
                    path="/floprotect/deductible-guarantee"
                    component={ DeductibleGuarantee }
                  />
                </Route>
                <Redirect
                  from="/homeprotect"
                  to="/floprotect"
                  state={ { status: 301 } }
                />
                <Route path="friends" name="friends">
                  <IndexRoute
                    component={ Referrals }
                  />
                </Route>
              </Route>
              <Route path="/unsubscribe" component={ EmailUnsubscribed } />
              <Route path="/authorization">
                <IndexRoute
                  component={ Login }
                  onEnter={ onAuthorizationEnter }
                  redirectPath="/authorization/confirmation"
                />
                <Route
                  path="/authorization/confirmation"
                  component={ AuthorizationConfirmation }
                />
              </Route>
              <Route
                path="/l/all-included"
                component={ AccountCreationLanding }
              />
              <Route
                path="/l/location/confirmation"
                component={ LocationConfirmation }
              />
              <Route
                path="/l/user/creation"
                component={ AccountCreation }
              />
              <Route
                path="/l/email/confirmation"
                component={ EmailConfirmation }
              />
              <Route
                path="/l/email/confirmed/:token"
                component={ EmailConfirmed }
              />
              <Route path="*" component={ InvalidPath } />
            </Router>
          </StripeProvider>
        </MixpanelProvider>
      </Provider>);
  }
}
