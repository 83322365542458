"use strict";
    
import axios  from "axios";
import config from "config";

const logglyURI = "https://logs-01.loggly.com/inputs/f9ad03cb-f87d-4947-a658-06603b53b88e/tag/http/";
const options = {
	headers:{
		"content-type": "application/x-www-form-urlencoded"
	}
}

/**
 * Error API Interface
 */
class _Error {
	/**
   * Emit data to loggly
   * @param { Object } data - plain JSON object of data to transmit
   */
  emit = data  => {
		axios.post( logglyURI, data, { ...options, from: { ...config.environment } } );
	}
  /**
   * Emit an API specific Error from an 'Error<Object>'
   * @param { Error<Object> } e - An error
   */
  emitApiError = e => {
    this.emit({
      status: e.response.status,
			statusText: e.response.statusText,
			error: e.response.data.message
    })
  }
}
/**
 * @ignore
 */
export const Error = new _Error();
export default Error;
