"use strict";

import {
  UPDATE_TELEMETRY_AVERAGES,
  UPDATE_DAILY_GOAL_CONSUMPTION,
  UPDATE_MEASUREMENTS,
  UPDATE_SELECTED_DEVICE_INDEX,
  UPDATE_METRICS
} from "constants/action";

import ConsumptionAPI from "api/consumption";
import { Authenticate } from "actions/authentication";
import Device from "actions/device";
import Setting from "actions/setting";
import LocationProfileAction from "actions/locationProfile";
import Store from "store";
import moment from 'moment';

const updateDailyGoalConsumption = (timezone, usage, unitSystem) => ({
  type: UPDATE_DAILY_GOAL_CONSUMPTION,
  payload: {
    timezone,
    usage,
    unitSystem
  }
});

const updateMeasurements = (period, timezone, previousData, currentData, unitSystem, vitalsData) => ({
  type: UPDATE_MEASUREMENTS,
  period,
  timezone,
  previousData,
  currentData,
  unitSystem,
  vitalsData
});

const updateMetrics = (period, timezone, unitSystem, vitalsData, selectedDeviceIndex) => ({
  type: UPDATE_METRICS,
  period,
  timezone,
  unitSystem,
  vitalsData,
  selectedDeviceIndex
});

const updateSelectedDeviceIndex = selectedDeviceIndex => ({
  type: UPDATE_SELECTED_DEVICE_INDEX,
  selectedDeviceIndex,
});

const getSettingsByPeriod = (period, previous = false) => {
  switch (period) {
    case "last_day": {
      const start = previous ? moment().subtract(24, 'hours') : moment();
      const end = previous ? moment().subtract(24, 'hours') : moment();
      return {
        start: start.startOf('day').utc().toISOString(),
        end: end.endOf('day').utc().toISOString(),
        interval: '1h'
      }
    }
    case "last_24_hours": {
      const start = previous ? moment().subtract(48, 'hours') : moment().subtract(24, 'hours');
      const end = previous ? moment().subtract(24, 'hours') : moment();
      return {
        start: start.startOf('hour').utc().toISOString(),
        end: end.startOf('hour').utc().toISOString(),
        interval: '1h'
      }
    }
    case "this_week": {
      const start = previous ? moment().subtract(7, 'days').startOf('week') : moment().startOf('week');
      const end = previous ? moment().subtract(7, 'days').endOf('week') : moment().endOf('week');
      return {
        start: start.subtract(1, 'day').toISOString(),
        end: end.subtract(1, 'day').toISOString(),
        interval: '1d'
      }
    }
    case "last_28_days": {
      const start = previous ? moment().subtract(56, 'days') : moment().subtract(28, 'days');
      const end = previous ? moment().subtract(28, 'days') : moment();
      return {
        start: start.add(1, 'days').startOf('day').utc().toISOString(),
        end: end.endOf('day').utc().toISOString(),
        interval: '1d'
      }
    }
    case "last_12_months": {
      const start = previous ? moment('2018-09-10') : moment().subtract(12, 'months');
      const end = previous ? moment().subtract(12, 'months') : moment();
      return {
        start: start.add(1, 'month').startOf('month').utc().format('YYYY-MM-DD'),
        end: end.endOf('month').utc().format('YYYY-MM-DD'),
        interval: '1m'
      }
    }
    default:
      return getSettingsByPeriod('last_24_hours');
  }
}

/**
 * Consumption Action Module
 */
class _Consumption {

  updateSelectedDeviceIndex(selectedDeviceIndex) {
    Store.dispatch(updateSelectedDeviceIndex(selectedDeviceIndex));
  }

  @Authenticate()
  getDailyGoalConsumption() {
    const period = "last_day";
    const userState = Store.getState().user;
    if (userState.locations.length < 1) {
      return Promise.reject("Current location not set");
    }

    const locationId = userState.currentLocation.id;
    const current = getSettingsByPeriod(period);

    const tz = moment.tz.guess();
    return ConsumptionAPI.getMeasurements(current.start, current.end, locationId, current.interval, tz)
      .then((data)  => {
        Store.dispatch(updateDailyGoalConsumption(moment.tz.guess(), data, userState.unitSystem));
      })
      .catch(error => console.log(error));
  }

  @Authenticate()
  getMeasurements(period = "last_day", selectedDeviceIndex = 0) {
    const userState = Store.getState().user;
    if (userState.locations.length < 1) {
      return Promise.reject("Current location not set");
    }

    const locationId = userState.currentLocation.id;
    const device = userState.currentLocation.devices.length > 0 ? userState.currentLocation.devices[selectedDeviceIndex] : null;

    const current = getSettingsByPeriod(period);
    const previous = getSettingsByPeriod(period, true);

    const tz = moment.tz.guess();
    return Promise.all([
        ConsumptionAPI.getMeasurements(previous.start, previous.end, locationId, previous.interval, tz),
        ConsumptionAPI.getMeasurements(current.start, current.end, locationId, current.interval, tz),
        device ? ConsumptionAPI.getMetrics(current.start, current.end, device.macAddress, current.interval, tz) : Promise.resolve(null)
      ])
      .then(([previousData, currentData, vitalsData])  => {
        Store.dispatch(updateMeasurements(period, tz, previousData, currentData, userState.unitSystem, vitalsData));
      })
      .catch(error => console.log(error));
  }

  @Authenticate()
  getMetrics(period = "last_day", selectedDeviceIndex = 0) {
    const userState = Store.getState().user;
    if (userState.locations.length < 1) {
      return Promise.reject("Current location not set");
    }

    const locationId = userState.currentLocation.id;
    const device = userState.currentLocation.devices.length > 0 ? userState.currentLocation.devices[selectedDeviceIndex] : null;
    if (!device) {
      return Promise.reject("Device does not exist");
    }

    const current = getSettingsByPeriod(period);

    const tz = moment.tz.guess();
    return ConsumptionAPI.getMetrics(current.start, current.end, device.macAddress, current.interval, tz)
      .then(vitalsData => {
        Store.dispatch(updateMetrics(period, tz, userState.unitSystem, vitalsData, selectedDeviceIndex));
      })
      .catch(error => console.log(error));
  }
}

/**
 * @ignore
 */
export const Consumption = new _Consumption();
export default Consumption;
