"use strict";

import React, { Component }       from 'react';
import { connect }                from "react-redux";
import EmailPreferencesActions    from 'actions/emailPreferences';
import EmailPreferencesForm       from 'components/elements/EmailPreferencesForm';
import _                          from 'lodash';

class EmailPreferences extends Component {

  constructor( props ){
    super( props );

    this.state = {
      errorMessage: ""
    };
  }

  componentDidMount(){
    EmailPreferencesActions.hydrateStore();
  }

  save = (formValues) => {
    const subscriptions = _.reduce(formValues, (result, value) => ({
        ...result,
        [value.email_id]: value.subscribed
    }), {});

    if(subscriptions.weekly_report) {
      EmailPreferencesActions.subscribeWeeklyReport(this.props.userId, this.props.locationId);
    } else {
      EmailPreferencesActions.unsubscribeWeeklyReport(this.props.userId, this.props.locationId);
    }

    EmailPreferencesActions.updateUserPreferences(this.props.userId, subscriptions);
  }

  render() {
    return (
      <div className="card h-100">
        <div className="card-header">
          <span>Email Preferences</span>
        </div>
        <div className="card-body">
          <EmailPreferencesForm
            initialValues={ this.props.preferences }
            errorMessage={ this.state.errorMessage }
            onSubmit={ this.save }
          />
        </div>
      </div>
    );
  }
}

const ConnectedEmailPreferences = connect( state =>
  ({
    locationId: state.locationProfile.location.location_id,
    userId: state.user.user_id,
    preferences: state.emailPreferences.preferences
  })
)( EmailPreferences );

export default ConnectedEmailPreferences;
