import React, { PureComponent } from 'react';
import PropTypes from "prop-types";
import CancelSubscriptionForm from 'elements/CancelSubscriptionForm';

class CancelSubscription extends PureComponent {

  constructor(props, context) {
    super(props, context);
		this.state = {
      showReasonForm: false,
		};
  }

  handleContinue = event => {
    event.preventDefault();
    this.setState({ showReasonForm: true });
  }

  renderBenefitsScreen() {
    return (
      <div className="benefits-screen">
        <div className="row">
          <div className="col-md-12">
            <span><strong>What happens when I downgrade my FloProtect?</strong></span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <span>The following benefits & features offered with FloProtect will no longer be active:</span>
            <ul className="dashed">
                <li>$2,500 Water Damage Deductible Guarantee</li>
                <li>Proactive Monitoring and Live Troubleshooting Support</li>
                <li>Fixture Detection and Enhanced Water Usage Data</li>
                <li>Extended 3-Year Product Warranty</li>
                <li>Potential Discounts on Your Home Insurance Policy</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <span>Are you sure you want to pause your FloProtect benefits?</span>
          </div>
        </div>
        <div className="center-block">
          <button className="btn btn-lg btn-subscribe btn-cancel-subscription btn-back" onClick={this.props.handleClose}>Keep Benefits</button>
          <button className="btn btn-lg btn-subscribe btn-cancel-subscription btn-continue" onClick={this.handleContinue}>Continue</button>
        </div>
      </div>
    );
  }

  renderCancelSubscriptionForm() {
    return (
      <div className="reason-form">
        <div className="row">
          <div className="col-md-12">
            <span className="reason-title">Can you please provide us with additional details on why you are cancelling your FloProtect membership?</span>
          </div>
        </div>

        <CancelSubscriptionForm 
          errorMessage  = { this.props.errorMessage }
          onSubmit      = { this.props.handleSubmit }
          onClose       = { this.props.handleClose }
        />
      </div>
    );
  }

  render() {
    return (
      <div className={ 'checkout ' + this.props.stateClass }>
        <div className="credit-card-payment cancel-subscription">

          <div className="row">
            <div className="col-12">
              <h2>Downgrade FloProtect</h2>
            </div>
          </div>

          { !this.state.showReasonForm && this.renderBenefitsScreen() }
          { this.state.showReasonForm && this.renderCancelSubscriptionForm() }
        </div>

        <div className="success">
          <div className="icon">
            <svg width="84px" height="84px" viewBox="0 0 84 84" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
              <circle className="border" cx="42" cy="42" r="40" strokeLinecap="round" strokeWidth="4" stroke="#000" fill="none"></circle>
              <path className="checkmark" strokeLinecap="round" strokeLinejoin="round" d="M23.375 42.5488281 36.8840688 56.0578969 64.891932 28.0500338" strokeWidth="4" stroke="#000" fill="none"></path>
            </svg>
          </div>
          <h3 className="title">FloProtect Successfully Downgraded</h3>
          <p className="message lg-text"><span>Thank you for your feedback!</span></p>
          { this.props.cancelAtPeriodEnd && this.props.status == 'active' &&
            <p className="note lg-text"><span><strong>Your subscription will be downgraded at the end of the current billing period.</strong></span></p>
          }
          <button className="btn btn-lg btn-subscribe btn-subscribe-pro" onClick={this.props.handleClose}>Return to Manage FloProtect</button>
        </div>
      </div>
    );
  }
}

CancelSubscription.propTypes = {
  stateClass: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string.isRequired,
  cancelAtPeriodEnd: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
};

export default CancelSubscription;
