"use strict";

import React, { PureComponent} from 'react';
import { Formik, Field }       from 'formik';
import  _                      from 'lodash';

class EmailPreferencesInnerForm extends PureComponent {
  render() {
    const {
      values,
      handleSubmit,
      isSubmitting,
      errorMessage
    } = this.props;

    return (
      <form onSubmit={handleSubmit} className="form" novalidate>
        { errorMessage !== '' &&
        <div className="message-container m-3">
          <span className="error-message">{ errorMessage }</span>
        </div>
        }

        {
          _.map(values, (preference, index) => (
            <div className="form-group">
              <div className="form-check">
                <Field
                  type="checkbox"
                  name={ `${index}.subscribed` }
                  className={ "form-check-input" }
                  checked={ preference.subscribed }
                />
                <label className="form-check-label">
                  { preference.name }
                </label>
              </div>
            </div>
          ))
        }

        <button type="button" className="btn btn-primary" onClick={ handleSubmit } disabled={isSubmitting}>
          Save
        </button>
      </form>
    );
  }
}

class EmailPreferencesForm extends PureComponent {
  render() {
    const errorMessage = this.props.errorMessage;

    return (
      <Formik
        initialValues={ this.props.initialValues }
        enableReinitialize={ true }
        onSubmit={(
          values,
          { setSubmitting }
        ) => {
          const result = this.props.onSubmit(values);

          if(Promise.resolve(result) == result) {
            result
              .then(_ => setSubmitting(false))
              .catch(_ => setSubmitting(false));
          } else {
            setSubmitting(false);
          }
        }}
        render={
          formikProps =>
            <EmailPreferencesInnerForm
              { ...formikProps }
              errorMessage={ errorMessage }
              handleChange={ (event) => this.handleChange(formikProps, event) }
            />
        }
      />
    );
  }
}

export default EmailPreferencesForm;
