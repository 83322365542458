"use strict";

import axios from "axios";
import config from "config";

const customerEmailURI = `${config.environment.api.uri}/api/v1/customeremail`;

class _EmailPreferences {

  getAllEmails() {
    return axios
      .get(`${customerEmailURI}`)
      .then(success => success.data);
  }

  getUserPreferences(userId) {
    return axios
      .get(`${customerEmailURI}/subscription/${userId}`)
      .then(success => success.data);
  }

  updateUserPreferences(userId, preferences) {
    return axios
      .put(`${customerEmailURI}/subscription/${userId}`, preferences)
      .then(success => success.data);
  }
}
/**
 * @ignore
 */
export const EmailPreferences = new _EmailPreferences();
export default EmailPreferences;
