"use strict";

import { UPDATE_FIXTURE_DETECTION, UPDATE_FLO_DETECT_DATA, LOADING_FLO_DETECT, UPDATE_FLOW_EVENTS, INITIALIZE_FLOW_EVENTS_NEW_PAGE } from "constants/action";
import { Authenticate } from "actions/authentication";
import Device from "actions/device";
import Store from "store";
import floDetectAPI from "api/floDetect";
import UnitSystem from 'constants/UnitSystem';

const FLOW_EVENTS_PAGE_SIZE = 20;

class _FloDetect {

  @Authenticate()
  retrieveLatestInRange(deviceId, seconds) {
      floDetectAPI
        .retrieveLatestInLastHours(deviceId, seconds)
        .then(response => Store.dispatch({
          type: UPDATE_FIXTURE_DETECTION,
          data: response
        }))
  }

  @Authenticate()
  retrieveLatestInLastHours(hoursInSeconds, timezone) {
    const devicePromise = Device.get();
    const userStore = Store.getState().user;
    const unitSystem = userStore.unitSystem || UnitSystem.IMPERIAL;

    devicePromise.catch(() => Store.dispatch({
      type: LOADING_FLO_DETECT,
      data: {
        isLoading: false
      }
    }));

    return devicePromise
      .then( device => {
        Store.dispatch({
          type: LOADING_FLO_DETECT
        });

        return floDetectAPI
          .retrieveLatestInLastHours(device.device_id, hoursInSeconds, timezone)
          .then(response => {
            if (response.isLearning || response.hasNoDataToDisplay || !response.id) {
              Store.dispatch({
                type: UPDATE_FLO_DETECT_DATA,
                data: response,
                unitSystem
              });
              return Promise.resolve();
            }

            return floDetectAPI
              .retrieveFlowEvents(response.id, undefined, FLOW_EVENTS_PAGE_SIZE, "desc")
              .then(events => {
                Store.dispatch({
                  type: UPDATE_FLO_DETECT_DATA,
                  data: {
                    ...response,
                    flowEvents: events.data.items,
                  },
                  unitSystem
                });
              });
          })
      });
  }

  @Authenticate()
  retrieveMoreFlowEvents(computationId, fixtureUsageDetails) {
    if (!computationId) {
      return console.error('computation Id is missing in the store. Flow events could not be retrieved');
    }
    if (!fixtureUsageDetails || fixtureUsageDetails.length < 1) {
      return console.error('fixtureUsageDetails is missing in the store or has invalid data');
    }

    const userStore = Store.getState().user;
    const unitSystem = userStore.unitSystem || UnitSystem.IMPERIAL;
    const lastEvent = fixtureUsageDetails[fixtureUsageDetails.length -1];
    Store.dispatch({ type: INITIALIZE_FLOW_EVENTS_NEW_PAGE });

    return floDetectAPI
    .retrieveFlowEvents(computationId, lastEvent.start, FLOW_EVENTS_PAGE_SIZE, "desc")
    .then(events => {
      Store.dispatch({
        type: UPDATE_FLOW_EVENTS,
        data: { flowEvents: events.data.items },
        unitSystem
      });
    })
    .catch((err) => console.error(err));
  }

  @Authenticate()
  saveValidations(validations, computationId, startDate) {
    return floDetectAPI.saveValidations(validations, computationId, startDate)
  }
}

/**
 * @ignore
 */
export const floDetect = new _FloDetect();
export default floDetect;
