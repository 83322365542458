"use strict";

import { UPDATE_FIXTURE_DETECTION, UPDATE_FLO_DETECT_DATA, LOADING_FLO_DETECT, UPDATE_FLOW_EVENTS, INITIALIZE_FLOW_EVENTS_NEW_PAGE } from "constants/action";
import _ from 'lodash';
import { convertFromGallons } from 'constants/UnitSystem';

const initialState = {
  requestId: null,
  deviceId: null,
  startDate: null,
  endDate: null,
  createdAt: null,
  status: null,
  isLoading: true,
  fixtures: [
  ],
  isLearning: true,
  hasNoDataToDisplay: false,
  fixtureUsageDetails: [],
  hasMoreFixtureUsageDetails: false,
  isUsageDetailsLoading: false,
  fixturesUsage: []
};

/**
 * Allowed Actions in the Fixture Detection Reducer
 */
const fixtureDetectionActions = [
  UPDATE_FIXTURE_DETECTION,
  UPDATE_FLO_DETECT_DATA,
  LOADING_FLO_DETECT,
  UPDATE_FLOW_EVENTS,
  INITIALIZE_FLOW_EVENTS_NEW_PAGE
];

function getColorByType(type) {
  switch (type) {
    case 1:
      return "#AB00B5";
    case 2:
      return "#FFA400";
    case 3:
      return "#3B84C2";
    case 4:
      return "#FF1400";
    case 5:
      return "#9B9B9B";
    case 6:
      return "#17D3E6";
    case 7:
      return "#8DC748";
    default:
      return "#b4b4b4";
  };
}


export const fixtureDetection = (state = initialState, action) => {
  if (action && action.type && fixtureDetectionActions.indexOf(action.type) > -1) {
    switch (action.type) {
      case LOADING_FLO_DETECT:
        return {
          ...state,
          isLoading: (action.data && typeof action.data.isLoading !== 'undefined') ? action.data.isLoading : true
        };
      case UPDATE_FIXTURE_DETECTION:
        const result = _.mapKeys(action.data, (v, k) => _.camelCase(k));

        return {
          ...state,
          ...result,
          isLoading: false
        };
        break;
      case UPDATE_FLO_DETECT_DATA:
        const data = action.data;
        const fixtureUsageDetails = _.orderBy(data.flowEvents || [], ['start'], ['desc']) || [];

        return {
          ...state,
          isLearning: data.isLearning,
          hasNoDataToDisplay: data.hasNoDataToDisplay,
          isLoading: false,
          deviceId: data.device_id,
          computationId: data.id,
          fixtureUsageDetails: fixtureUsageDetails.map(x => ({
            ...x,
            flow: convertFromGallons(x.flow, action.unitSystem),
            unitSystem: action.unitSystem
          })),
          hasMoreFixtureUsageDetails: fixtureUsageDetails.length > 0,
          fixturesUsage:  _.orderBy(
            (data.fixtures  || []).map(x => ({
              ...x,
              type: x.type,
              color: getColorByType(x.type),
              value: convertFromGallons(x.gallons, action.unitSystem),
              unitSystem: action.unitSystem
            })), ['gallons'], ['desc']
          ),
        };
        break;
      case UPDATE_FLOW_EVENTS:
        const flowEventsData = _.orderBy((action.data && action.data.flowEvents) || [], ['start'], ['desc']) || [];
        const flowEvents = flowEventsData.map(x => ({
          ...x,
          flow: convertFromGallons(x.flow, action.unitSystem),
          unitSystem: action.unitSystem
        }));

        return {
          ...state,
          fixtureUsageDetails: [
            ...state.fixtureUsageDetails,
            ...flowEvents
          ],
          hasMoreFixtureUsageDetails: flowEvents.length > 0,
          isUsageDetailsLoading: false,
        };
        break;
      case INITIALIZE_FLOW_EVENTS_NEW_PAGE:
        return  {
          ...state,
          hasMoreFixtureUsageDetails: false,
          isUsageDetailsLoading: true,
        };
        break;
      default: break;
    }
  }
  return state;
}

export default fixtureDetection;
