"use strict";

import {
  UPDATE_CONSUMPTION_BY_FIXTURE,
  UPDATE_DAILY_GOAL_CONSUMPTION,
  UPDATE_MEASUREMENTS,
  UPDATE_DAILY_GOAL_TOTALS,
  UPDATE_SELECTED_DEVICE_INDEX,
  UPDATE_METRICS
} from "constants/action";

import moment from 'moment-timezone';
import _ from 'lodash';
import UnitSystem, {
  displayLongVolumeUnit,
  displayShortVolumeUnit,
  convertFromGallons,
  Unit,
  Converter
} from 'constants/UnitSystem';

const units = {
  temperature: 'ºF',
  pressure: 'PSI',
  consumption: 'gal',
  flow: 'gal/min'
};

/**
 * Builds X-Axis values based off a period
 * @param { string } period - Predefined period
 * @return { Array } - of values conditionally set by the period
 */
const buildInfo = (period, unit) => {
  const returnObj = {};

  returnObj.xAxis = [
    '12:00 am', '02:00 am', '04:00 am', '06:00 am', '08:00 am', '10:00 am', '12:00 pm',
    '02:00 pm', '04:00 pm', '06:00 pm', '08:00 pm', '10:00 pm'
  ];

  if (period === '28_days' || period === 'last_28_days') {
    returnObj.formatter = tootltipFormatterWithTwoDates(28, unit);
  } else if ( period === 'this_week' || period === '1_week') {
    returnObj.formatter = tootltipFormatterWithTwoDates(7, unit);
  }

  return { formatter: tooltipFormatter(unit), ...returnObj };
}

function tooltipFormatter(unit, labelsGenerator) {
  return (params, ticket, callback) => {
    const axisValues = params[ 0 ].axisValue.split( /\n/ );
    const color = seriesId => params[seriesId].color;
    const defaultLabels = {
      unique: axisValues[0],
      first: axisValues[0],
      second: axisValues[1]
    };
    const labels = labelsGenerator ? labelsGenerator(params) : {};
    const consumed = params[0].data;

    labels.unique = labels.unique || defaultLabels.unique;
    labels.first = labels.first || defaultLabels.first;
    labels.second = labels.second || defaultLabels.second;

    if (params.length > 1) {
      return tooltipDetail(labels.first, consumed, unit, color(0)) +
        '<br />' +
        tooltipDetail(labels.second || labels.first, params[1].data, unit, color(1));
    } else {
      return tooltipDetail(labels.unique, consumed, unit, color(0));
    };
  };
}

function tooltipDetail(label, value, unit, color) {
  const dotStyle = `display:inline-block;border-radius:5px;width:10px;height:10px;margin-right:2px;background:${ color };`;

  return `<span style="${ dotStyle }"></span>${ label }: ${ value } ${ unit }`;
}

function tootltipFormatterWithTwoDates(secondDateAtXDaysBefore, unit) {
  return tooltipFormatter(unit, (params) => {
    var date;

    if(secondDateAtXDaysBefore == 7) {
      date = getStartOfThisWeek().add(params[0].dataIndex % 7, 'days');
    } else {
      date = moment().subtract(secondDateAtXDaysBefore - params[0].dataIndex - 1, 'days');
    }

    const day = date.format('dddd');
    const dateOne = date.format('M/D');
    const dateTwo = date.clone().subtract(secondDateAtXDaysBefore, 'days').format('M/D');

    return {
      unique: `${ day } (${dateOne})`,
      first: `${ day } (${dateOne})`,
      second: `${ day } (${dateTwo})`
    };
  });
}

function getStartOfThisWeek() {
  return moment().startOf('week').subtract(1, 'day');
}

/**
 * Initial State of Consumption Store
 */
const initialState = {
  todayTotal: 0,
  periodLength: undefined,
  selectedDeviceIndex: 0, // for vital measurements
  vitals: {
    flowGraphOptions: buildOptions(units.flow),
    flowGraphDownloadData: [],
    pressureGraphOptions: buildOptions(units.pressure),
    pressureGraphDownloadData: [],
    temperatureGraphOptions: buildOptions(units.temperature),
    temperatureGraphDownloadData: []
  },
  gallonsConsumedToday: 0,
  dailyGoalTotals: buildOptions(
    'gal',
    undefined,
    "Goal Gallons Remaining",
    [
      {
        type: "line",
        smooth: true
      },
    ]
  ),
  totalGraphOptions: buildOptions(
    'gal',
    {
      legend: { data: [ "Current", "Previous", "Goal" ] },
      calculable: true
    },
    "Gallons",
    [
      {
        name: "Current",
        barCategoryGap: "50%",
      },
      {
        name: "Previous",
        barCategoryGap: "50%",
        itemStyle:{
          normal: { color: "#2b5876" },
          emphasis: { color: "#27516c" },
        }
      }
    ]
  ),
  totalGraphDownloadData: [],
	byFixture:[
							{ label: "shower", value: 1, color: "#7CC76E" },
							{ label: "toilet", value: 1, color: "#3E8CCD" },
							{ label: "faucet", value: 1, color: "#E5A34D" },
							{ label: "dishwasher", value: 1, color: "#B473C0" },
							{ label: "laundry", value: 1, color: "#DD4F4F" },
							{ label: "other", value: 1, color: "#B3B3B3" },
						]
};


function buildOptions(unit, generalConfigs = {}, yAxisName, seriesConfigs = [{}]) {
  const defaultSeriesConfig = { data: new Array( 30 ).fill( 0 ), type: 'bar' };
  const series = seriesConfigs.map(config => buildSeries({ ...defaultSeriesConfig, ...config }));
  const { xAxis, formater } = buildInfo("last_day", unit);

  return {
    ...{
      tooltip: { trigger: "axis" },
      formatter:  formater
    },
    ...generalConfigs,
    xAxis: [ buildXAxis(xAxis) ],
    yAxis: [ buildYAxis(yAxisName) ],
    series
  };
}

function buildYAxis(name) {
  const additionalConfigs = {};

  if(name) {
    additionalConfigs.name = name;
  }

  return {
    type: "value",
    axisLabel: { textStyle: { color: "#333333" } },
    axisLine:{ lineStyle: { color: "#DFDFDF" } },
    splitLine: { lineStyle: { color: "#DFDFDF" } },
    nameTextStyle:{ color: "#333333" },
    ...additionalConfigs
  }
};

function buildXAxis(data) {
  return {
    data,
    type: "category",
    axisLabel: { textStyle: { color: "#333333" } },
    axisLine: { lineStyle: { color: "#DFDFDF" } },
    splitLine: { lineStyle: { color: "#DFDFDF" } },
  };
}

function buildSeries(config) {
  return {
    barCategoryGap: "25%",
    itemStyle: {
      normal: { color: "#4292BE" },
      emphasis: { color: "#50A1CC" },
    },
    ...config
  };
}

function getDataDownloadFromGraphOptions(headers, yAxisData, xAxisData) {
  const rows = _.zip(xAxisData, yAxisData.slice(0, xAxisData.length));
  return _.concat([headers], rows);
}

function getDataDownloadHeaders(periodId, isPreviousPeriod, unit) {
  const previousHeader = ['Previous period', unit];

  if(isPreviousPeriod) {
    return previousHeader;
  }

  switch (periodId) {
    case "last_24_hours":
      return ['Last 24 Hours (hourly)', unit];
    case "last_day":
      return ['Today (hourly)', unit];
    case "this_week":
      return ['This Week (daily)', unit];
    case "last_28_days":
      return ['Four Weeks (daily)', unit];
    case "last_12_months":
      return ['Last 12 Months (monthly)', unit];
    case "today_every_hour":
      return ['Current', unit];
    default:
      return ['Current', unit];
  }
}

function getXAxis(period, timezone, data, inDataDownloadFormat) {
  let tz = timezone;
  if (period === 'last_12_months') {
    tz = 'UTC';
  }
  return data.map(x => moment(x.time).tz(tz).format(xAxisFormatByPeriodId(period, inDataDownloadFormat)));
}

function updateTotalGraphOptions(totalGraphOptions, period, timezone, previousData, currentData, unitSystem) {
  const unit = displayShortVolumeUnit(unitSystem);
  const options = { ...totalGraphOptions };
  const currentSeries = currentData.map(x => formatDataPoint(x.total_flow));
  let previousSeries;
  if (period !== 'last_12_months') {
    previousSeries = previousData.map(x => formatDataPoint(x.total_flow));
  } else {
    previousSeries = previousData.reduce((acc, x) => {
      return [
        ...acc,
        formatDataPoint(x.total_flow)
      ];
    }, Array(12 - previousData.length).fill(0));
  }
  const totalGraphDownloadData = new Array();
  const unitSystemLongText = _.capitalize(displayLongVolumeUnit(unitSystem));

  options.xAxis[0].data = getXAxis(period, timezone, currentData, false);
  options.tooltip.formatter = buildTooltipFormatter(period, unit);
  options.tooltip.alwaysShowContent = true;
  options.period = period;
  options.series[0].data = currentSeries;
  options.series[1].data = previousSeries;
  totalGraphDownloadData.push(
    getDataDownloadFromGraphOptions(
      getDataDownloadHeaders(period, false, unitSystemLongText),
      currentSeries,
      getXAxis(period, timezone, currentData, true)
    )
  );
  totalGraphDownloadData.push(
    getDataDownloadFromGraphOptions(
      getDataDownloadHeaders(period, true, unitSystemLongText),
      previousSeries,
      getXAxis(period, timezone, previousData, true)
    )
  );

  return {
    totalGraphOptions: options,
    totalGraphDownloadData
  };
}

function updateVitalsGraphOptions(vitals, period, timezone, data, unitSystem) {
  const vitalsCopy = { ...vitals };
  const reducer = (acc, value) => {
    acc.flow_rate.push(Converter.convertFromGallons(value.averageGpm, unitSystem));
    acc.pressure.push(Converter.convertFromPSI(value.averagePsi, unitSystem));
    acc.temperature.push(Converter.convertFromFahrenheit(value.averageTempF, unitSystem));
    return acc;
  };

  const initialAverages = {
    flow_rate: [],
    pressure: [],
    temperature: []
  };

  const averages = data.reduce(reducer, initialAverages);

  Object.keys(averages).forEach(averageKey => {
    const key = averageKey === "flow_rate" ? "flow" : averageKey;
    const xAxis = getXAxis(period, timezone, data, false);
    const series = averages[averageKey].map(value => formatDataPoint(value));

    const unitHeader = `${_.capitalize(key)} (${getDisplayUnitSystem(key, unitSystem, true)})`;
    const headers = getDataDownloadHeaders(period, false, unitHeader);

    const downloadData = getDataDownloadFromGraphOptions(
      headers, series, getXAxis(period, timezone, data, true)
    );

    vitalsCopy[key + "GraphOptions"].series[0].data = series;
    vitalsCopy[key + "GraphOptions"].xAxis[0].data = xAxis;
    vitalsCopy[key + "GraphOptions"].period = period;
    vitalsCopy[key + "GraphOptions"].tooltip.formatter = buildTooltipFormatter(period, getDisplayUnitSystem(key, unitSystem));
    vitalsCopy[key + "GraphOptions"].tooltip.alwaysShowContent = true;
    vitalsCopy[key + "GraphDownloadData"] = [ downloadData ];
  });

  return vitalsCopy;
}

function getDisplayUnitSystem(key, unitSystem, isLongDisplay = false) {
  let unit;
  switch (key) {
    case 'temperature':
      unit = isLongDisplay ? Unit.displayLongTempUnit(unitSystem) : Unit.displayShortTempUnit(unitSystem);
      break;
    case 'flow':
      unit = isLongDisplay ? Unit.displayLongFlowUnit(unitSystem) : Unit.displayShortFlowUnit(unitSystem);
      break;
    case 'pressure':
      unit = isLongDisplay ? Unit.displayLongPressureUnit(unitSystem) : Unit.displayShortPressureUnit(unitSystem);
      break;
    default:
  }
  return unit;
}

function updateDailyGoalTotalsGraphOptions(dailyGoalTotalsOptions, timezone, data, unitSystem = UnitSystem.IMPERIAL) {
  const period = 'last_day';
  const until = moment().hours();
  const unit = displayShortVolumeUnit(unitSystem);
  const options = { ...dailyGoalTotalsOptions };

  options.series[0].data = data.map(x => formatDataPoint(x.usage)).slice(0, until);
  options.xAxis[0].data = getXAxis(period, timezone, data, false);
  options.xAxis[0].boundaryGap = false;
  options.tooltip.formatter = buildTooltipFormatter(period, unit);
  options.tooltip.alwaysShowContent = true;

  return options;
}

function formatDataPoint(dataPoint) {
  return parseFloat(dataPoint.toFixed(2));
}

function buildTooltipFormatter(period, unit) {
  if (period === 'last_28_days') {
    return tootltipFormatterWithTwoDates(28, unit);
  } else if (period === 'this_week') {
    return tootltipFormatterWithTwoDates(7, unit);
  }

  return tooltipFormatter(unit);
}

function xAxisFormatByPeriodId(periodId, inDataDownloadFormat = false) {
  switch(periodId) {
    case "last_24_hours":
      return inDataDownloadFormat ? 'MM/DD/YYYY hh:mm a' : 'hh a';
    case "last_day":
      return inDataDownloadFormat ? 'MM/DD/YYYY hh:mm a' : 'hh:mm a';
    case "this_week":
      return inDataDownloadFormat ? 'ddd MM/DD/YYYY' : 'dddd';
    case "last_28_days":
      return inDataDownloadFormat ? 'ddd MM/DD/YYYY' : 'dd';
    case "12_months":
    case "last_12_months":
      return inDataDownloadFormat ? 'MM-YY' : 'MMM';
    case "today_every_hour":
      return 'hh:mm a';
    default:
      return 'MM/DD/YYYY hh:mm a';
  };
}

function convertDataValuesTo(unitSystem = UnitSystem.IMPERIAL, data) {
  return data.map(datum => ({
    ...datum,
    total_flow: convertFromGallons(datum.total_flow, unitSystem),
    unitSystem
  }));
}

/**
 * Consumption Reducer
 * @param { Object } state - State of the 'Consumption' Store
 * @param { Object } action - Action Object. Always has action.type, which should match a constant
 * @return { Object } - Updated Consumption Store
 */
export const consumption = ( state = initialState, action ) => {
  switch( action.type ){
    case UPDATE_MEASUREMENTS: {
      const unitSystem = action.unitSystem || UnitSystem.IMPERIAL;

      const { totalGraphOptions, totalGraphDownloadData } = updateTotalGraphOptions(
        state.totalGraphOptions,
        action.period,
        action.timezone,
        convertDataValuesTo(action.unitSystem, action.previousData),
        convertDataValuesTo(action.unitSystem, action.currentData),
        unitSystem
      );

      const vitals = updateVitalsGraphOptions(
        state.vitals, action.period, action.timezone, action.vitalsData, unitSystem
      );

      return {
        ...state,
        totalGraphOptions,
        totalGraphDownloadData,
        vitals,
        periodLength: action.period
      };
    }

    case UPDATE_METRICS: {
      const unitSystem = action.unitSystem || UnitSystem.IMPERIAL;
      const vitals = updateVitalsGraphOptions(
        state.vitals, action.period, action.timezone, action.vitalsData, unitSystem
      );

      return {
        ...state,
        vitals,
        selectedDeviceIndex: action.selectedDeviceIndex
      };
    }

    case UPDATE_DAILY_GOAL_CONSUMPTION: {

      const hourlyUsage = action.payload.usage.map(agg => ({
        usage: formatDataPoint(agg.total_flow),
        time: agg.time
      }));
      const unitSystem = action.payload.unitSystem || UnitSystem.IMPERIAL;

      return {
        ...state,
        gallonsConsumedToday: hourlyUsage.reduce((accumulator, currentValue) => accumulator + currentValue.usage, 0),
        dailyGoalTotals:  updateDailyGoalTotalsGraphOptions(
          state.dailyGoalTotals, action.payload.timezone, hourlyUsage, unitSystem
        )
      };
    }
    case UPDATE_CONSUMPTION_BY_FIXTURE: {
      if (action.fixture) {
        // find fixture in current array and update
        return {
          ...state,
          byFixture: state.byFixture.map(fixture => fixture.label === action.fixture.name ? action.fixture : fixture)
        };
      } else if (action.fixtures){
        return { ...state, byFixture: action.fixtures };
      } else {
        return state;
      }
    }

    case UPDATE_SELECTED_DEVICE_INDEX: {
      return { ...state, selectedDeviceIndex: action.selectedDeviceIndex };
    }

    case UPDATE_DAILY_GOAL_TOTALS: {
      const unitSystem = action.unitSystem || UnitSystem.IMPERIAL;
      return {
        ...state,
        dailyGoalTotals: buildOptions(
          displayShortVolumeUnit(unitSystem),
          undefined,
          `Goal ${_.capitalize(displayLongVolumeUnit(unitSystem))} Remaining`,
          [
            {
              type: "line",
              smooth: true
            },
          ]
        ),
        totalGraphOptions: buildOptions(
          displayShortVolumeUnit(unitSystem),
          {
            legend: { data: [ "Current", "Previous", "Goal" ] },
            calculable: true
          },
          _.capitalize(displayLongVolumeUnit(unitSystem)),
          [
            {
              name: "Current",
              barCategoryGap: "50%",
            },
            {
              name: "Previous",
              barCategoryGap: "50%",
              itemStyle:{
                normal: { color: "#2b5876" },
                emphasis: { color: "#27516c" },
              }
            }
          ]
        )
      };
    }

    default:
      return state;
  }
};

export default consumption;
