"use strict";

import React, { Component }                       from "react";
import { connect }                                from "react-redux";
import { passwordComplexityRegex }                from "constants/regex";
import {Elements}                                 from 'react-stripe-elements';
import InjectedCheckoutForm                       from 'elements/CheckoutForm';
import InjectedPaymentMethodForm                  from 'elements/UpdatePaymentMethod';
import InjectedCancelSubscription                 from 'elements/CancelSubscription';
import ShieldLogo                                 from 'elements/ShieldLogo';
import FloProtectActions                          from 'actions/floprotect';
import LocationProfileActions                     from 'actions/locationProfile';
import {Helmet}                                   from "react-helmet";
import queryString                                from 'query-string';
import AlertsPanel                                from "elements/AlertsPanel";
import PropTypes                                  from 'prop-types';
import { Popover, PopoverBody, Modal, ModalBody } from 'reactstrap';
import {browserHistory}                           from "react-router";
import User                                       from "../../actions/user";

export class FloProtect extends Component {

  constructor(props, context) {
    super(props, context);

		this.state = {
      showCheckoutModal: false,
      showCancelFloProtectModal: false,
      popoverOpenProactiveMonitoringInfo: false,
      popoverOpenDeducibleGuaranteeInfo: false
		};
  }

  componentDidMount() {
    const mixpanel = this.context.mixpanel;
    const queryParams = queryString.parse(this.props.location.search);

    if (queryParams.location) {
      User.updateNextLocation(queryParams.location);
    }

    mixpanel.track(
      "Viewed User Portal Subscription"
    );
    FloProtectActions.hydrateStore(queryParams.plan_id || 'default', queryParams.source_id, queryParams.coupon_id);
  }

  subscribe = stripe => event => {
    if (event) {
      event.preventDefault();
    }

    const mixpanel = this.context.mixpanel;
    const queryParams = queryString.parse(this.props.location.search);

    const subscriptionPromise = (stripe) ? 
      FloProtectActions.subscribe(stripe, this.props.user.id, this.props.plan.plan_id, this.props.sourceId, this.props.couponInfo.id)
      :
      FloProtectActions.subscribeUsingCreditCardOnFile(this.props.user.id, this.props.plan.plan_id, this.props.sourceId, this.props.couponInfo.id);

    subscriptionPromise.then(() => {
      mixpanel.track(
        "Subscription Successful",
        {
          "Subscription_Source": queryParams.source_id || 'user-portal',
          "Plan_ID": queryParams.plan_id || 'default'
        }
      );
    });
  }

  updatePaymentMethod = stripe => env => {
    env.preventDefault();
    FloProtectActions.updatePaymentMethod(stripe, this.props.user.id)
  }

  handleCancelSubscription = values => {
    FloProtectActions.cancelSubscription(this.props.subscriptionId, values.reason);
  }

  handleCloseCheckoutModal = () => {
    const queryParams = queryString.parse(this.props.location.search);
    
    this.setState({ showCheckoutModal: false });
    FloProtectActions.clearErrorMessage();
    FloProtectActions.cleanPaymentFormState();

    if(queryParams.source_id === 'insurance_letter') {
      browserHistory.push('/floprotect/insurance-letter');
    }
  }

  handleCloseUpdatePaymentMethodModal = () => {
    FloProtectActions.updatePaymentMethodFormInfo({ isModalOpen: false });
    FloProtectActions.clearErrorMessage();
    FloProtectActions.cleanPaymentFormState();
  }

  handleShowCheckoutModal = () => {
    const mixpanel = this.context.mixpanel;
    const queryParams = queryString.parse(this.props.location.search);
    FloProtectActions.loadPaymentInfo(this.props.user.id);
    mixpanel.track(
      "Clicked Subscribe",
      {
        "Subscription_Source": queryParams.source_id || 'user-portal',
        "Plan_ID": queryParams.plan_id || 'default'
      }
    );
    this.setState({showCheckoutModal: true});
  }

  handleCloseCancelFloProtectModal = () => {
    this.setState({ showCancelFloProtectModal: false });
    FloProtectActions.cleanPaymentFormState();
  }

  handleShowCancelFloProtectModal = () => {
    this.setState({showCancelFloProtectModal: true});
  }

  toggleProactiveMonitoringInfo = () => {
    this.setState({
      popoverOpenProactiveMonitoringInfo: !this.state.popoverOpenProactiveMonitoringInfo
    });
  }

  toggleDeducibleGuaranteeInfo = () => {
    this.setState({
      popoverOpenDeducibleGuaranteeInfo: !this.state.popoverOpenDeducibleGuaranteeInfo
    });
  }

  /**
   * React Lifecycle: render - Is fired when the <Settings_UserProfile /> component is leveraged to draw to the screen.
   */
  render() {
    const floProtectEnabled = this.props.floProtect.enabled;
    const { cancelAtPeriodEnd } = this.props;
    const isSubscriptionActive = floProtectEnabled && !cancelAtPeriodEnd;

    const basicPlanButton =
      (<button className="btn btn-lg btn-subscribe" onClick={this.handleShowCancelFloProtectModal} disabled={!isSubscriptionActive}>
        <div>
          {floProtectEnabled && <span>Downgrade</span>}
          {!floProtectEnabled && <span><i className="fas fa-check"></i>  Current Plan</span>}
        </div>
      </button>);

    const floProtectPlanButton =
      (<button className="btn btn-lg btn-subscribe btn-subscribe-pro" onClick={this.handleShowCheckoutModal} disabled={isSubscriptionActive || !this.props.user.id}>
        {isSubscriptionActive && <span ><i className="fas fa-check"></i>  Current Plan</span>}
        {!isSubscriptionActive && <span>Add FloProtect</span>}
      </button>);

    return (
      <div className="container home-protect">
        <Helmet>
          <title>Flo - FloProtect</title>
        </Helmet>

        { this.props.couponInfo.name &&
          <div className="row">
            <div className="col-12">
              <div className="alert alert-info" role="alert">
                { "Coupon applied: " + this.props.couponInfo.name + " - " + this.props.couponInfo.percent_off + "% off " }
              </div>
            </div>
          </div>
        }

        <div className="card home-protect-content">
          <div className="card-body">
            <h1>Guarantee Your Home’s Safety From Water Damage</h1>

            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 short-description">
                <h3 className="text-center">
                  Try FloProtect Free for 30 Days. <br/>
                  Then just ${this.props.plan.monthly_cost}/month. No Long Term Contracts.
                </h3>
              </div>
            </div>

            <div className="row justify-content-center attached">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 order-md-12">
                <div className="wrapper">
                  <div className="card pricing pro details">
                    <div className="card-header plan pro-plan">
                      <div className="row justify-content-center">
                        <div className="col-12 pricing-title">
                          <div className="d-inline">
                            <ShieldLogo isActive={false} size="big"/>
                          </div>
                          <div className="d-inline align-middle">
                            FloProtect
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="description">
                        Add an additional layer of protection to your home
                      </div>
                      <div>
                        {floProtectPlanButton}
                      </div>
                      <div className="plan-details">
                        <div className="additional-info">In addition to Standard Protection</div>
                        <ul>
                          <li>Water Damage Deductible Guarantee
                            <a
                              id="popover-deducible-guarantee-info"
                              className="popover-icon"
                              onFocus={ this.toggleDeducibleGuaranteeInfo }
                              onMouseEnter={ this.toggleDeducibleGuaranteeInfo }
                              onMouseLeave={ this.toggleDeducibleGuaranteeInfo }
                            >
                              <i className="far fa-question-circle"></i>
                            </a>
                            <Popover isOpen={this.state.popoverOpenDeducibleGuaranteeInfo} target="popover-deducible-guarantee-info" toggle={ this.toggleDeducibleGuaranteeInfo }>
                              <PopoverBody>If your home has a Flo device installed with FloProtect and still gets water damage, we’ll cover the cost of your insurance deductible, up to $2,500.</PopoverBody>
                            </Popover>
                          </li>
                          <li>Proactive Monitoring + Water Concierge
                            <a
                              id="popover-proactive-monitoring-info"
                              className="popover-icon"
                              onFocus={ this.toggleProactiveMonitoringInfo }
                              onMouseEnter={ this.toggleProactiveMonitoringInfo }
                              onMouseLeave={ this.toggleProactiveMonitoringInfo }
                            >
                              <i className="far fa-question-circle"></i>
                            </a>
                            <Popover placement="bottom" isOpen={this.state.popoverOpenProactiveMonitoringInfo} target="popover-proactive-monitoring-info" toggle={ this.toggleProactiveMonitoringInfo }>
                              <PopoverBody>Our team of experts will monitor your home and will proactively find solutions for you when we detect a problem.</PopoverBody>
                            </Popover>
                          </li>
                          <li>Enhanced Water Usage Data</li>
                          <li>Extended Product Warranty</li>
                          <li>Insurance Verification Letter</li>
                          <li>Live Chat Troubleshooting Support</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 order-md-1">
                <div className="wrapper">
                  <div className="card pricing basic details">
                    <div className="card-header plan basic-plan">
                      Standard Protection
                    </div>
                    <div className="card-body">
                      <div className="description">
                        Standard leak detection & basic usage data
                      </div>
                      <div>
                        {basicPlanButton}
                      </div>
                      <div className="plan-details">
                        <ul>
                          <li>Automatic Water Shutoff</li>
                          <li>Leak Detection and Prevention</li>
                          <li>Email & Phone Alerts</li>
                          <li>Water Usage Data</li>
                          <li>1 Year Product Warranty</li>
                          <li>Ticket-Based Support</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 press-logos">
                <img src="/assets/images/press-logos@2x.png" className="img-fluid" />
              </div>
            </div>

            <div className="row home-protect-banner text-center">
              <div className="col-md-4">
                <div>
                  <img src="/assets/images/stats.png" />
                </div>
                <h3>Proactive Monitoring</h3>
                <p>Our team of experts will monitor your home and will proactively find solutions for you if we detect a problem. </p>
              </div>
              <div className="col-md-4">
                <div>
                  <img src="/assets/images/guarantee.png" />
                </div>
                <h3>Water Damage Deductible Guarantee</h3>
                <p>If your home has a Flo device installed and gets water damage, we’ll cover the cost of your insurance deductible, up to $2,500.
                  <br/>
                  <br/>
                  <a href="https://support.meetflo.com/hc/en-us/sections/360000858434-FloProtect" target="_blank">Learn more</a> about what’s covered.
                </p>
              </div>
              <div className="col-md-4">
                <div>
                  <img src="/assets/images/chart.png" />
                </div>
                <h3>Deep Usage Insights</h3>
                <p>See your historical consumption, track against  water saving goals, and understand which fixtures impact your usage the most.</p>
              </div>
            </div>



            <div className="row">
              <div className="col-12">

                <div className="table-responsive">
                  <table className="table plans-features-table">
                    <thead>
                      <tr>
                        <th scope="col">Compare Plans</th>
                        <th scope="col">Standard Protection</th>
                        <th scope="col">
                          <div className="d-inline">
                            <ShieldLogo isActive={false} size="medium"/>
                          </div>
                          <div className="d-inline align-middle">
                            FloProtect
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr>
                      <td scope="row">
                        Proactive Alarms
                        (incl. FloSense Technology)
                      </td>
                      <td><i className="fas fa-check"></i></td>
                      <td><i className="fas fa-check"></i></td>
                    </tr>
                    <tr>
                      <td scope="row">
                        Daily Health Tests
                        (incl. MicroLeak Technology)
                      </td>
                      <td><i className="fas fa-check"></i></td>
                      <td><i className="fas fa-check"></i></td>
                    </tr>
                    <tr>
                      <td scope="row">Auto + Remote Water Shutoff</td>
                      <td><i className="fas fa-check"></i></td>
                      <td><i className="fas fa-check"></i></td>
                    </tr>
                    <tr>
                      <td scope="row">Water Damage Guarantee*</td>
                      <td></td>
                      <td>$2,500</td>
                    </tr>
                    <tr>
                      <td scope="row">Limited Product Warranty</td>
                      <td>1 year</td>
                      <td>3 years</td>
                    </tr>
                    <tr>
                      <td scope="row">Proactive Monitoring</td>
                      <td></td>
                      <td><i className="fas fa-check"></i></td>
                    </tr>
                    <tr>
                      <td scope="row">Product Support</td>
                      <td><i className="fas fa-check"></i></td>
                      <td><i className="fas fa-check"></i></td>
                    </tr>
                    <tr>
                      <td scope="row">Water Usage by Fixture</td>
                      <td></td>
                      <td><i className="fas fa-check"></i></td>
                    </tr>
                    <tr>
                      <td scope="row">Conservation Goal</td>
                      <td><i className="fas fa-check"></i></td>
                      <td><i className="fas fa-check"></i></td>
                    </tr>
                    <tr>
                      <td scope="row">Data Analytics</td>
                      <td>Basic</td>
                      <td>Enhanced</td>
                    </tr>
                    <tr>
                      <td scope="row">Live Chat Support</td>
                      <td></td>
                      <td><i className="fas fa-check"></i></td>
                    </tr>
                    <tr>
                      <td scope="row">Water Concierge</td>
                      <td></td>
                      <td><i className="fas fa-check"></i></td>
                    </tr>
                    <tr>
                      <td scope="row">Insurance Verification Letter</td>
                      <td></td>
                      <td><i className="fas fa-check"></i></td>
                    </tr>
                    <tr>
                      <td scope="row"></td>
                      <td>{ basicPlanButton }</td>
                      <td>{ floProtectPlanButton }</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="row chat-with-us">
              <div className="col-md-12">
                <div className="content text-center">
                  <h3>Chat with our team about FloProtect</h3>
                  <p>
                    Have specific questions? Chat our team using the chat on the bottom right.
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>

        <Modal
          isOpen={this.state.showCheckoutModal}
          toggle={this.handleCloseCheckoutModal}
        >
          <ModalBody>
            <Elements>
              <InjectedCheckoutForm
                plan={this.props.plan}
                sourceId={this.props.sourceId}
                stateClass={this.props.checkoutFormState}
                handleSubmit={this.subscribe}
                handleClose={this.handleCloseCheckoutModal}
                errorMessage={this.props.errorMessage}
                paymentInfo={this.props.updatePaymentMethod.paymentInfo}
              />
            </Elements>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.props.updatePaymentMethod.isModalOpen}
          toggle={this.handleCloseUpdatePaymentMethodModal}
        >
          <ModalBody>
            <Elements>
              <InjectedPaymentMethodForm
                stateClass={this.props.checkoutFormState}
                handleSubmit={this.updatePaymentMethod}
                handleClose={this.handleCloseUpdatePaymentMethodModal}
                errorMessage={this.props.errorMessage}
                paymentInfo={this.props.updatePaymentMethod.paymentInfo}
              />
            </Elements>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.showCancelFloProtectModal}
          toggle={this.handleCloseCancelFloProtectModal}
        >
          <ModalBody className="cancel-subscription-modal">
            <Elements>
              <InjectedCancelSubscription
                stateClass={this.props.checkoutFormState}
                handleSubmit={this.handleCancelSubscription}
                handleClose={this.handleCloseCancelFloProtectModal}
                errorMessage={this.props.errorMessage}
                cancelAtPeriodEnd={this.props.cancelAtPeriodEnd}
                status={this.props.status}
              />
            </Elements>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

FloProtect.contextTypes = {
  mixpanel: PropTypes.object.isRequired
};


export default connect( state => ({
    floProtect: {
      enabled: state.subscription.isSubscribedToFloProtect
    },
    user: {
      ...state.user
    },
    checkoutFormState: state.subscription.checkoutFormState,
    subscriptionId: state.subscription.id,
    errorMessage: state.subscription.errorMessage,
    plan: state.subscription.plan,
    sourceId: state.subscription.sourceId,
    couponInfo: state.subscription.couponInfo,
    updatePaymentMethod: state.subscription.updatePaymentMethod,
    cancelAtPeriodEnd: state.subscription.cancelAtPeriodEnd,
    status: state.subscription.status,
  })
)( FloProtect );
