"use strict";

import { UPDATE_LOCATION_PROFILE } from "constants/action";

/**
 * Initial State for LocationProfile Store
 */
const initialState = {
  location: {
    nickname      : "",
    address       : "",
    address2      : "",
    city          : "",
    country			  : "",
    state         : "",
    postalCode    : "",
    timezone      : ""
  },
  states          : [],
  countries       : [],
  timezones       : []
};

/**
 * LocationProfile  Reducer
 * @param { Object } state - State of the 'LocationProfile' Store
 * @param { Object } action - Action Object. Always has action.type, which should match a constant
 * @return { Object } - Updated LocationProfile Store
 */
export const locationProfile = ( state = initialState, action ) => {
  switch( action.type ){
    case UPDATE_LOCATION_PROFILE: {
      return {
        ...state,
        ...action.data
      };
    }
    default:
      return state;
  }
}

export default locationProfile;
