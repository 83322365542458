"use strict";

import {
  UPDATE_INSURANCE_LETTER_DOWNLOAD_INFO,
  UPDATE_INSURANCE_LETTER_STATUS,
  REDEEM_INSURANCE_LETTER,
  UPDATE_LOADING_FILE
} from "constants/action";
import InsuranceLetterStatus from "constants/InsuranceLetterStatus";
import moment from 'moment-timezone';

const initialState = {
  status: InsuranceLetterStatus.UNKNOWN,
  document_download_url: null,
  date_redeemed: moment().subtract(6, 'months'),
  loadingFile: false,
};

const insuranceLetter = (state = initialState, action) => {
  switch(action.type) {
    case UPDATE_INSURANCE_LETTER_DOWNLOAD_INFO: {
      return {
        ...state,
        ...action.data,
        date_redeemed: action.data.date_redeemed ? moment.utc(action.data.date_redeemed) : null,
        expiration_date: action.data.expiration_date ? moment.utc(action.data.expiration_date) : null,
        renewal_date: action.data.renewal_date ? moment.utc(action.data.renewal_date) : null
      };
    };break;
    case UPDATE_INSURANCE_LETTER_STATUS: {
      return {
        ...state,
        status: action.data.status
      };
    };break;
    case REDEEM_INSURANCE_LETTER: {
      return {
        ...state,
        ...action.data
      };
    }
    case UPDATE_LOADING_FILE: {
      return { ...state, loadingFile: action.isLoading };
    }
  }
  return state;
}

export default insuranceLetter;
