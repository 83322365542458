"use strict";

import React, { Component }   from "react";
import { connect }            from "react-redux";
import Dialog                 from "elements/Dialog";
import {Helmet}               from "react-helmet";
import { getFormValues }      from 'redux-form';
import HomeProfileForm        from "elements/HomeProfileForm";
import LocationProfileActions from "actions/locationProfile";
import SettingsActions        from "actions/setting";
import HomeProfileSkeleton from 'elements/HomeProfileSkeleton';

/**
 * Settings -> Home Profile Component
 */
export class Settings_HomeProfile extends Component {

  constructor( props ){
    super(props);
    this.state = {
      submitting: false
    };
  }

  componentDidMount(){
    LocationProfileActions.hydrateStore();
    SettingsActions.hydrateHomeProfileFields();
  }

  save = formValues => {
    const { homeProfileFields } = this.props;
    this.setState( {submitting: true} );

    const locationData = homeProfileFields.reduce((acc, field) => {
      if (formValues[field.fieldName] !== undefined) {
        const current = { [field.fieldName]: (field.type === 'number') ? parseFloat(formValues[field.fieldName]) : formValues[field.fieldName] };
        return {
          ...acc,
          ...current
        }
      } else {
        return acc;
      }
    }, {});

    return LocationProfileActions.saveLocationProfile(formValues.id, locationData).then(() => {
      this.setState({ submitting: false });
    });
  }

  render() {
    return (
      <section className="container settings-home-profile">
        <Helmet>
          <title>Flo - Home Profile</title>
        </Helmet>

        <div className="row">
          <div className="col-12 bottom-margin">
            <div className="card">
              <div className="card-header">
                Home Profile
              </div>
              <div className="card-body">
                { !this.props.loadingProfileFields &&
                  <HomeProfileForm
                    initialValues={ this.props.location }
                    fields={ this.props.homeProfileFields }
                    onSubmit={ this.save }
                    submitting={ this.state.submitting }
                  />
                }
                { this.props.loadingProfileFields && <HomeProfileSkeleton /> }
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const ConnectedHomeProfile = connect( state =>
  ({
    location: {
      ...state.locationProfile.location
    },
    homeProfileFormValues: getFormValues('user-localtion-profile-form')(state),
    homeProfileFields: state.settings.homeProfileFields,
    loadingProfileFields: state.settings.loadingProfileFields,
  })
)( Settings_HomeProfile );

export default ConnectedHomeProfile;
