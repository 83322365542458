"use strict";

var BASE_PATH = __dirname + "/";
var SRC_PATH = BASE_PATH + "src/";
var DIST_PATH = SRC_PATH + "dist/";
var BUILD_PATH = SRC_PATH + "build/";

var MODULES_PATH = BASE_PATH + "node_modules/";

var ALL_JS_GLOB = SRC_PATH + "**/*.js";

var STYLES_BASE_PATH = SRC_PATH + "styles/";

var CSS_OUTPUT_PATH = BUILD_PATH;

var config = {
  environment: {
    app: {
      protocol: "floapp-dev://"
    },
    host: {
      protocol: "https",
      domain: "",
      port: "8800",
      path: "",
      get uri() {
        return this.protocol + ":" + this.domain + ":" + this.port + this.path;
      }
    },
    api: {
      protocol: "https",
      domain: "//api-dev.flocloud.co",
      port: "443",
      path: "",
      get uri() {
        return this.protocol + ":" + this.domain + ":" + this.port + this.path;
      }
    },
    apiV2: {
      protocol: "https",
      domain: "//api-gw-dev.flocloud.co",
      port: "443",
      path: "",
      get uri() {
        return this.protocol + ":" + this.domain + ":" + this.port + this.path;
      }
    },
    firebase: {
      apiKey: "AIzaSyCQeS6GoSirWFlqBnXm7V1cj9HkHYXpgIo",
      authDomain: "flo-dev-ec388.firebaseapp.com",
      databaseURL: "https://flo-dev-ec388.firebaseio.com",
      projectID: "flo-dev-ec388"
    },
    mqtt: {
      protocol: "wss",
      domain: "//mqtt-dev.flocloud.co",
      port: "8000",
      path: "/mqtt",
      get uri() {
        return this.protocol + ":" + this.domain + ":" + this.port + this.path;
      }
    },
    stripe: {
      publicKey: "pk_test_FNVNEGPx5ze7K4k3o0bj3WbG"
    },
    mixpanel: {
      publicKey: "b219e9f70e22e15bdbfd68db248091a9"
    }
  },
  navigation: {
    usage: ["total-consumption", "vital-measurements"],
    alerts: "",
    settings: ["user-profile", "alert-settings", "contacts", "devices", "consumption-goals", "home-profile"],
    support: ""
  },
  paths: {
    dist: DIST_PATH
  },
  test: {
    paths: {
      base: SRC_PATH,
      watch: ALL_JS_GLOB,
      ignore: [DIST_PATH, MODULES_PATH, BUILD_PATH]
    }
  },
  styles: {
    paths: {
      base: STYLES_BASE_PATH,
      output: CSS_OUTPUT_PATH
    }
  }
};

module.exports = config;