"use strict";

import axios from "axios";
import config from "config";
import moment from 'moment/moment';
import FloDetectResponse from '../constants/FloDetectResponse';

const twentyFourHours = 86400;

const apiURIV2 = config.environment.apiV2.uri + "/api/v2";
const flodetectV2URI = `${apiURIV2}/flodetect/computations`;
const retrieveDevicesV2URI = (deviceId, duration) =>
  `${flodetectV2URI}/?macAddress=${deviceId}&duration=${duration}`;
const retrieveFlowEventsV2URI = computationId =>
  `${flodetectV2URI}/${computationId}/events`;
const postValidationV2URI = (computationId, start) =>
  `${retrieveFlowEventsV2URI(computationId)}/start/${start}`;

class _FloDetect {

  saveValidations(validations, computationId, startDate) {
    return axios.post(postValidationV2URI(computationId, startDate), validations);
  }

  retrieveLatestInLastHours(deviceId, hoursInSeconds, timezone = 'US/Pacific') {
    const duration = (hoursInSeconds === twentyFourHours) ? "24h" : "7d";

    return axios
      .get(retrieveDevicesV2URI(deviceId, duration),
      {
        validateStatus: function (status) {
          return status < 400 || status == 404;
        }
      })
      .then(res => {
        if (res.data.status == FloDetectResponse.LEARNING) {
          return { isLearning: true };
        }
        if (res.data.status == FloDetectResponse.INSUFFICENT_DATA || 
            res.data.status == FloDetectResponse.INTERNAL_ERROR ||
            res.status == 404) {
          return { hasNoDataToDisplay: true };
        }

        return { ...res.data, isLearning: false, hasNoDataToDisplay: false };
      });
  }

  retrieveFlowEvents(computationId, start, size, order) {
    const params = [];

    if (start) {
      params.push(`start=${start}`);
    }
    if (size) {
      params.push(`size=${size}`);
    }
    if (order) {
      params.push(`order=${order}`);
    }
    const parameters = (params.length > 0) ? `?${params.join("&")}` : '';
    return axios.get(retrieveFlowEventsV2URI(computationId) + parameters);
  }
}

export const floDetect = new _FloDetect();
export default floDetect;
