"use strict";

import React, { Component }                               from "react";
import { connect }                                        from "react-redux";
import { passwordComplexityRegex }                        from "constants/regex";
import EmailPreferencesActions                            from 'actions/emailPreferences';
import {Helmet}                                           from "react-helmet";
import queryString                                        from 'query-string';
import { createSkeletonProvider, createSkeletonElement }  from '@trainline/react-skeletor';
import SubscriptionStatus from 'constants/SubscriptionStatus';

const H3 = createSkeletonElement('h3', 'pending-div');
const H4 = createSkeletonElement('h4', 'pending-div');
const P = createSkeletonElement('p', 'pending-div');

const dummyData = {
  title: '___ _____ __ __ _______',
  paragraph1: '___ _____ __ __ ____________ ___ ________ _________ ',
  paragraph2: '___ _____ __ __ ____________ ___ ________ _________ ___ _____ __ __ ____________ ___ ________ _________ ',
  paragraph3: '___ _____ __ __ ____________ ___ ________ _________ ___ _____ __ __ ____________ ___ ________ _________ '
};

const pendingPredicate = (props) => {
  return props.subscriptionStatus == SubscriptionStatus.UNKNOWN ||
    props.subscriptionStatus == SubscriptionStatus.UNSUBSCRIBING;
}

export class EmailUnsubscribed extends Component {

  constructor(props, context) {
    super(props, context);
  }

  componentDidMount(){
    const queryParams = queryString.parse(this.props.location.search);
    EmailPreferencesActions.unsubscribeByEmbeddedLink(queryParams.u, queryParams.l, queryParams.n, queryParams.t);
  }

  getTitleBySubscriptionStatus() {
    switch (this.props.subscriptionStatus) {
      case SubscriptionStatus.UNSUBSCRIBED:
        return "You're unsubscribed";
      case SubscriptionStatus.UNSUBSCRIBE_FAILURE:
        return "There was an error while trying to unsubscribe";
      default:
        return dummyData.title;
    }
  }

  getParagraph1BySubscriptionStatus() {
    switch (this.props.subscriptionStatus) {
      case SubscriptionStatus.UNSUBSCRIBED:
        return "You have been successfully unsubscribed from Weekly Flo Report";
      case SubscriptionStatus.UNSUBSCRIBE_FAILURE:
        return "";
      default:
        return dummyData.paragraph1;
    }
  }

  getParagraph2BySubscriptionStatus() {
    switch (this.props.subscriptionStatus) {
      case SubscriptionStatus.UNSUBSCRIBED:
        return "We apologize if we've overwhelmed your inbox. Please feel free to reach us and tell how we can improve.";
      case SubscriptionStatus.UNSUBSCRIBE_FAILURE:
        return "You can unsubscribe directly from your ";
      default:
        return dummyData.paragraph2;
    }
  }

  getParagraph3BySubscriptionStatus() {
    switch (this.props.subscriptionStatus) {
      case SubscriptionStatus.UNSUBSCRIBED:
        return "If you would like to resubscribe to this email, please visit your ";
      case SubscriptionStatus.UNSUBSCRIBE_FAILURE:
        return "If you encounter any issues, please contact Flo Support at ";
      default:
        return dummyData.paragraph3;
    }
  }

  /**
   * React Lifecycle: render - Is fired when the <Settings_UserProfile /> component is leveraged to draw to the screen.
   */
  render() {
    const title = this.getTitleBySubscriptionStatus();
    const paragraph1 = this.getParagraph1BySubscriptionStatus();
    const paragraph2 = this.getParagraph2BySubscriptionStatus();
    const paragraph3 = this.getParagraph3BySubscriptionStatus();

    const paragraph2Link = !pendingPredicate(this.props) && this.props.subscriptionStatus == SubscriptionStatus.UNSUBSCRIBE_FAILURE ?
      <a href="/settings/user-profile">Account</a> :
      "";

    var paragraph3Link = "";

    if(!pendingPredicate(this.props)) {
      if(this.props.subscriptionStatus == SubscriptionStatus.UNSUBSCRIBE_FAILURE) {
        paragraph3Link = <a href="tel:844.MEET.FLO">844.MEET.FLO</a>;
      } else {
        paragraph3Link = <a href="/settings/user-profile">Account</a>;
      }
    }

    return (
      <section className="email-unsubscribe">
        <Helmet>
          <title>Flo - Unsubscribe from Weekly Flo Report</title>
        </Helmet>

        <div className="row">
          <div className="card-container col-xs-12 col-sm-8 col-md-6 col-lg-5 col-xl-4">
            <div className="card">
              <div className="card-body">
                <div className="floIcon text-center">
                  <img src="/assets/images/FloByMoenLogo.png" />
                </div>
                <div className="text-center">
                  <H3>{title}</H3>
                </div>
                <H4>{paragraph1}</H4>
                <P>{paragraph2} {paragraph2Link}</P>
                <P>{paragraph3} {paragraph3Link}</P>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default connect( state => ({
    ...state,
    subscriptionStatus: state.emailPreferences.subscriptionStatus
  })
)(createSkeletonProvider(dummyData, pendingPredicate)(EmailUnsubscribed));
