"use strict";

import React, { PureComponent }     from "react";
import PropTypes                    from 'prop-types';
import SlideSelect                  from "elements/SlideSelect";
import Dialog                       from "elements/Dialog";
import SystemMode                   from 'constants/SystemMode';

const slideSelectValues = [ "home", "away", "sleep" ];

export class SystemModeSelector extends PureComponent {

  constructor(props) {
    super(props);
    this.handleSetSystemMode = this.handleSetSystemMode.bind(this);
  }

  handleSetSystemMode(mode) {
    const { disabled, onSetSystemMode, onSetSystemSleep, selectedSystemMode } = this.props;

    if ( disabled ) { return false; }
    if ( mode === "sleep" ) {
      new Dialog( "FloModal", "warning four-buttons" )
        .custom( (<div className="inner-wrapper">
            During Sleep Mode the Flo system will not send you any alerts for the specified period of time. How long do you want the system to be in Sleep mode?
          </div>),
          {
            buttons: [
              {
                html: (
                  <div data-reaction="fulfill" data-value={ 60 * 2 }>2 Hours</div>
                )
              },
              {
                html: (
                  <div data-reaction="fulfill" data-value={ 60 * 24 }>24 Hours</div>
                )
              },
              {
                html: (
                  <div data-reaction="fulfill" data-value={ 60 * 72 }>72 Hours</div>
                )
              },
              {
                reaction: "reject",
                html: "Cancel"
              }
            ]
          }
        )
        .then( minutes => {
          const { active: lastMode } = this.refs.deviceMode.state;
          this.refs.deviceMode.startAnimating();
          onSetSystemSleep(SystemMode.fromName(lastMode).id, minutes)
            .then(() => this.refs.deviceMode.stopAnimating())
            .catch( e => {
              new Dialog( "FloModal", "warning" ).ok( <div className="inner-wrapper">{ e }</div> );
              this.refs.deviceMode.stopAnimating();
            });
        });
    } else {
      this.refs.deviceMode.startAnimating();
      onSetSystemMode(SystemMode.fromName(mode).id)
        .then(() => this.refs.deviceMode.stopAnimating())
        .catch( e => {
          new Dialog( "FloModal", "warning" ).ok( <div className="inner-wrapper">{ e }</div> );
          this.refs.deviceMode.stopAnimating();
        });
    }
  }

  render() {
    return (<SlideSelect
      nodes={ slideSelectValues }
      controlName="Mode"
      className="mode"
      active={ this.props.selectedSystemMode }
      ref="deviceMode"
      onSelect={ this.handleSetSystemMode }
      skipModal={ true }
      disabled={ this.props.disabled }
      animatingClass="transitioning"
    />);
  }
}

SystemModeSelector.PropTypes = {
  selectedSystemMode: PropTypes.string,
  onSetSystemMode: PropTypes.func.isRequired,
  onSetSystemSleep: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
};

export default SystemModeSelector;
