"use strict";

import React, { Component } from "react";
import { connect }          from "react-redux";

import Alert                from "actions/alert";
import Device               from "actions/device";

import Dialog               from "elements/Dialog";
import SlideSelect          from "elements/SlideSelect";
import TableList            from "elements/TableList";
import {Helmet}             from "react-helmet";

/**
 * Alert Log View
 */
export class Alerts_Log extends Component {
  /**
   * React Lifecycle: Initilizes the component and sets the initial state
   * @param { Object } props - Initial incomming properties on the component
   */
  constructor( props ) {
    super();
    const alerts = props.store.alert;
    this.state = {
      ...alerts,
      pendingHeaders: { incident_id: { type: "checkbox", display: "" }, friendly_name: "Alert Message", system_mode_text: "System Mode", incident_time: { display: "Date/Time" } },
      clearedHeaders: { friendly_name: "Alert Message", severity: "Severity", system_mode_text: "System Mode", incident_time: { display: "Date/Time" } },
    }
  }
  /**
   * React Lifecycle: triggered before the component starts mounting
   * Bind's events for resizing
   */
  componentWillMount() {
    Alert.hydrateStore();
  }
  /**
   * React Lifecycle: Triggered as components properties are updated
   * @param { Object } nextProps - the new properties data set
   */
  componentWillReceiveProps( nextProps ) {
    this.setState( { ...nextProps.store.alert } );
  }

  /**
   * Clear's the selected set of pending alerts
   */
  clearPending() {
    if ( this.refs.warningTable.state.selected.length < 1 && this.refs.criticalTable.state.selected.length < 1) {
      new Dialog( "FloModal", "warning" ).ok( <div className="inner-wrapper">You have no alerts selected to clear.<br/>Please select one or more you wish to clear.</div> );
    } else {
      // clear checkboxes
      new Dialog( "FloModal", "warning" )
          .confirm( <div className="inner-wrapper">
                      Are you sure you want to clear the selected alerts?<br />
                      This will clear all related alerts.
                    </div> )
          .then( () => {
            const alertsToClear = this.refs.warningTable.state.selected.concat(this.refs.criticalTable.state.selected);
            Alert.clearPending(alertsToClear);
          });
    }
  }
  /**
   * Display Alert Message in dialog
   * @impliments {@link Dialog}
   */
  showAlert( row ) {
    const { alarms } = this.props;

    if ( row.refs.checkbox ) {
      const alarm = alarms[row.state.alarm_id];
      let options = { ...row.state, actions: alarm.actions };
      delete options.columns;
      new Dialog( "FloModal", options.severity )
            .alert( options[ "friendly_name" ], options[ "friendly_description" ], options )
            .then( () => {
              new Dialog( "FloModal", options.severity )
                  .ignoreAlert( "Clear this alert", "Do you want to ignore this alert if it happens again?", options )
                  .then(actionId => {
                    const action = options.actions.find(a => a.id = actionId);
                    Alert.userAction([ alarm.id ], action.snoozeSeconds);
                  });
            });
    } else {
      new Dialog( "FloModal", row.state.severity.toLowerCase() )
        .ok(
          <div className="inner-wrapper">
            <h4 className="title">{ row.state[ "friendly_name" ] }</h4>
            <div className="message-block">
              { row.state[ "friendly_description" ] }
            </div>
          </div>
        );
    }
  }
  /**
   * Renders the clear link when more than 0 items are selected
   * @return { JSX } - for the clear link
   */
  renderClear() {
    if ( this.state.pending.warning.length > 0 || this.state.pending.critical.length > 0 ) {
      return ( <a className="clear-alerts" onClick={ this.clearPending.bind( this ) }>clear alerts</a> );
    }
  }

  /**
   * Pagination action
   * @param { string } type - Pending or Cleared alert type to update pagination
   * @param { number } page - Number of the page of the data set to update too
   */
  paginate( type, page ) {
    if (type === "cleared") {
      Alert.fetchClearedAlerts(page);
    }
  }
  /**
   * React Lifecycle: render - Is fired when the <Alert_Log /> component is leveraged to draw to the screen.
   * @return { JSX } - a JSX Object
   */
  render() {
    return (
      <div className="container alerts-log" ref="elem">
        <Helmet>
          <title>Flo - Alerts</title>
        </Helmet>

        <div className="row">
          <div className="col-12">
            <div className="card alerts bottom-margin">
              <div className="card-header">
                <span className="pending-alerts-title icon-Bell">
                  Pending Alerts ({ this.state.pendingTotal })
                </span>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <span className="critical-alerts-subtitle">Critical Alerts</span>
                    <hr />
                    <TableList
                      className="critical-alerts table-striped"
                      columns={ this.state.pendingHeaders }
                      items={ this.state.pending.critical }
                      ref="criticalTable"
                      onRowClick={ this.showAlert.bind( this ) }
                      zeroCountMsg="You Are Secure" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <span className="warning-alerts-subtitle">Warning Alerts</span>
                    <hr />
                    <TableList
                      className="warning-alerts table-striped"
                      columns={ this.state.pendingHeaders }
                      items={ this.state.pending.warning }
                      ref="warningTable"
                      onRowClick={ this.showAlert.bind( this ) }
                      zeroCountMsg="You Are Secure" />
                    { this.renderClear() }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card bottom-margin">
              <div className="card-header">
                <span className="cleared-alerts-title icon-Bell">
                  Activity Log ({ this.state.clearedTotal })
                </span>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <TableList
                      className="cleared-alerts table-striped"
                      columns={ this.state.clearedHeaders }
                      items={ this.state.cleared }
                      totalItems={ this.state.clearedTotal }
                      onPaginate={ this.paginate.bind( this, "cleared" ) }
                      onRowClick={ this.showAlert.bind( this ) }
                      zeroCountMsg="No Activity History" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect( store =>
  ({
    store: {
      alert:{
        ...store.alert
      }
    },
    alarms: store.settings.alarms
  })
)( Alerts_Log );
