"use strict";

import { UPDATE_USER_DATA, FETCH_USER_LOCATIONS_SUCCESS, FETCH_ALARM_SETTINGS_SUCCESS, 
  UPDATE_ALARM_SETTINGS, UPDATE_LEAK_SENSITIVITY_SETTINGS, UPDATE_LEAK_SENSITIVITY, UPDATE_SYSTEM_MODE, UPDATE_NEXT_LOCATION } from "constants/action";
import _ from "lodash";
import UnitSystem from "../constants/UnitSystem";
/**
 * User store initial state
 */
const initialState = {
	firstName   : "",
  middlename  : "",
	lastName    : "",
  prefixname  : "",
  user_id     : "",
  phoneMobile: "",
  email       : "",
  is_active   : "",
  id          : "",
  unitSystem : UnitSystem.IMPERIAL,
  nextCurrentLocation: null,

  currentLocation: {},
  locations: [],
  alarmSettings: undefined,
  smallDripSensitivitySettings: []
};

/**
 * User Reducer
 * @param { Object } state - State of the 'User' Store
 * @param { Object } action - Action Object. Always has action.type, which should match a constant
 * @return { Object } - Updated User Store
 */
export const user = ( state = initialState, action ) => {
  switch(action.type){
    case UPDATE_USER_DATA: {
      return {
        ...state,
        ...action.data,
        locations: state.locations,
        alarmSettings: state.alarmSettings
      };
    }

    case UPDATE_LEAK_SENSITIVITY_SETTINGS: {
      const settings = action.settings;
      const smallDripSensitivitySettings = settings.map(s => ({
        deviceId: s.deviceId,
        smallDripSensitivity: s.smallDripSensitivity
      }));
      return { ...state, smallDripSensitivitySettings };
    }

    case UPDATE_LEAK_SENSITIVITY: {
      const deviceId = action.deviceId;
      const index = state.smallDripSensitivitySettings.findIndex(s => s.deviceId === deviceId);
      if (index < 0) {
        return { ...state };
      }
      return {
        ...state,
        smallDripSensitivitySettings: [
          ...state.smallDripSensitivitySettings.slice(0, index),
          { deviceId, smallDripSensitivity: action.newSensitivity },
          ...state.smallDripSensitivitySettings.slice(index + 1),
        ]
      };
    }

    case FETCH_ALARM_SETTINGS_SUCCESS: {
      const alarms = action.alarms;
      const alarmSettings = action.alarmSettings;

      const alarmsMap =_.keyBy(alarms.filter(a => !a.isInternal && a.active && a.deliveryMedium.userConfigurable), 'id');
      Object.keys(alarmsMap).forEach(alarmId => {
        const currentAlarm = alarmsMap[alarmId];
        if (currentAlarm.triggersAlarm && alarmsMap[currentAlarm.triggersAlarm.id]) {
          alarmsMap[currentAlarm.triggersAlarm.id].triggeredBy = parseInt(alarmId, 10);
        }
      });

      const settingsData = alarmSettings.map(device => {
        const settings = device.settings
          .filter(s => alarmsMap[s.alarmId])
          .map(s => {
            const alarm = alarmsMap[s.alarmId];
            return {
              displayName: alarm.displayName,
              name: alarm.name,
              severity: alarm.severity,
              triggersAlarm: alarm.triggersAlarm && alarm.triggersAlarm.id,
              triggeredBy: alarm.triggeredBy,
              ...s
            };
          });

        return {
          ...device,
          settings
        };
      });

      const settingsBySeverity = settingsData.map(device => {
        const settingsGroupedBySeverity = _.groupBy(device.settings, "severity");
        for(const severity in settingsGroupedBySeverity) {
          const ungroupedAlarms = settingsGroupedBySeverity[severity];
          settingsGroupedBySeverity[severity] = _.groupBy(ungroupedAlarms, "alarmId");

          for (const alarm in settingsGroupedBySeverity[severity]) {
            const ungroupedSystemModes = settingsGroupedBySeverity[severity][alarm];
            settingsGroupedBySeverity[severity][alarm] = _.keyBy(ungroupedSystemModes, "systemMode");
          }
        }
        return {
          ...device,
          settings: settingsGroupedBySeverity
        };
      });
      return { ...state, alarmSettings: settingsBySeverity };
    }

    case UPDATE_ALARM_SETTINGS: {
      const index = state.alarmSettings.findIndex(devSettings => devSettings.deviceId === action.deviceId);
      if (index < 0) {
        return { ...state };
      }

      const currentDevice = state.alarmSettings[index];
      const newCurrentDevice = action.settings.reduce((acc, curr) => {
        return {
          ...acc,
          settings: {
            ...acc.settings,
            [curr.severity]: {
              ...acc.settings[curr.severity],
              [curr.alarmId]: {
                ...acc.settings[curr.severity][curr.alarmId],
                [curr.systemMode]: {
                  ...acc.settings[curr.severity][curr.alarmId][curr.systemMode],
                  smsEnabled: curr.smsEnabled,
                  emailEnabled: curr.emailEnabled,
                  pushEnabled: curr.pushEnabled,
                  callEnabled: curr.callEnabled
                }
              }
            }
          }
        };
      }, currentDevice);

      return {
        ...state,
        alarmSettings: [
          ...state.alarmSettings.slice(0, index),
          newCurrentDevice,
          ...state.alarmSettings.slice(index + 1),
        ]
      };
    }

    case FETCH_USER_LOCATIONS_SUCCESS: {
      return {
        ...state,
        locations: action.locations,
        currentLocation: action.current
      };
    }

    case UPDATE_SYSTEM_MODE: {
      if (state.currentLocation.id !== action.locationData.id) {
        throw new Error("Conflict: System Mode to be updated does not correspond to the current location.");
      }

      const currentLocation = {
        ...state.currentLocation,
        systemMode: {
          ...state.currentLocation.systemMode,
          ...action.locationData.systemMode
        }
      };

      const index = state.locations.findIndex(loc => loc.id === action.locationData.id);
      if (index < 0) {
        return { ...state };
      }
      const locations = [
        ...state.locations.slice(0, index),
        currentLocation,
        ...state.locations.slice(index + 1),
      ];

      return { ...state, currentLocation, locations };
    }

    case UPDATE_NEXT_LOCATION: {
      return { ...state, nextCurrentLocation: action.locationId };
    }

    default:
      return state;
  }
}
export default user;
